@import "../../../../../../../../../../style/variables.scss";

.label {
    font-size: 12px;
    font-weight: bold;
    color: #444;
    text-transform: uppercase;
    margin-top: $baseline;
    margin-bottom: $baseline / 4;

    &:first-child  {
        margin-top: 0;
    }
}

.switchWrapper {
    margin-top: $baseline;
}
