@import "../../../../style/variables.scss";

.wrapper {
    .languagesWrapper {
        border-bottom: 1px solid #3b3b3b;
    }
    @media (min-width: $breakpoint-l) {
        display: flex;
        align-items: center;

        .languagesWrapper {
            flex: 1;
            border-right: 1px solid #3b3b3b;
            border-bottom: 0;
        }
    }

    .language {
        padding: $baseline;
        display: flex;
        gap: $baseline;
        flex-direction: column;
        border-bottom: 1px solid #3b3b3b;

        &:last-child {
            border-bottom: 0;
        }

        @media (min-width: $breakpoint-s) {
            flex-direction: row;
        }

        .titleAndDescription {
            flex: 1;

            .title {
                font-weight: bold;
                color: #fff;
            }
            .description {
                font-size: 13px;
                color: #b1b1b1;

                &:first-letter {
                    text-transform: uppercase;
                }
            }
        }

        .formFields {
            display: flex;
            flex-direction: column;
            gap: $baseline;
            flex: 1;

            @media (min-width: $breakpoint-s) {
                flex: 1;
            }

            @media (min-width: $breakpoint-m) {
                flex-direction: row-reverse;
                align-items: center;
                gap: $baseline * 2;
                
                .inputWrapper {
                    flex: 1;
                }
            }

            &.hasOneLanguage {
                @media (min-width: $breakpoint-m) {
                    flex: 2;
                }
            }
            @media (min-width: $breakpoint-l) {
                flex-direction: row-reverse;
                align-items: center;
                gap: $baseline * 2;
            }
        }
    }

    .switchMultipleLanguagesWrapper {
        padding: $baseline;
    }
}
