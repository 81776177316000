@import "../../style/variables.scss";

.wrapper {
    display: inline-flex;
    padding: ($baseline / 3);
    border-radius: 4px;

    gap: $baseline / 3;
    background: $color-edit-action;
    background: #252526;

    & > *:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }

    & > *:last-child {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
}
