@import "../../style/variables.scss";
.selectViewWrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: $baseline * 2;
    margin-bottom: $baseline;
}

.confirmation {
    h3 {
        margin-top: 0;
        margin-bottom: $baseline / 2;
        letter-spacing: 1px;

        font-size: 22px;

        @media (min-width: $breakpoint-m) {
            font-size: 26px;
        }
    }

    .confirmationTitleError {
        color: $color-form-error;
    }

    .confirmationText {
    }
}

%themeBase {
    max-width: var(--generator-module-max-width);
    margin: 0 auto;
    padding: var(--generator-module-spacing);
    padding-bottom: var(--generator-module-spacing-bottom);

    .textAndFormWrapper {
        background: var(--generator-content-box-background);
        box-shadow: var(--generator-content-box-shadow);
        color: var(--generator-content-box-text-color);
        .rteWrapper {
            text-align: var(--generator-text-text-align);
        }
    }

    .buttonAndReceiverWrapper {
        margin-top: var(--generator-spacing-200);

        .receiverWrapper {
            margin-top: var(--generator-spacing-200);
        }
    }

    .showFormButtonWrapper {
        text-align: center;
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5,
.theme11,
.theme6 {
    @extend %themeBase;
}

.theme4 {
    .textAndFormWrapper {
        padding: var(--generator-content-box-padding);
    }
}
