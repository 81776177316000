@import "../../style/variables.scss";
%themeBase {
    text-decoration: none;
    display: inline-block;
    background: transparent;
    cursor: pointer;
    -webkit-appearance: none;
    border: 0;
}

%themeBase {
    display: inline-block;
    background: var(--generator-button-background);
    border: 1px solid var(--generator-button-background);
    color: var(--generator-button-text-color);
    font-family: var(--font-text);
    font-weight: var(--generator-button-font-weight);
    letter-spacing: var(--generator-button-letter-spacing);
    font-size: var(--generator-button-font-size);
    border-radius: var(--generator-button-radius);
    padding: var(--generator-button-padding);
    text-align: center;
    width: var(--generator-button-width);
    text-transform: var(--generator-button-text-transform);

    transition: transform 250ms cubic-bezier(0.2, 0.8, 0.4, 1);

    &.small {
        padding: var(--generator-button-small-padding);
        font-size: var(--generator-button-small-font-size);
    }

    &.secondary {
        // No good way to do this with the new color scheme?
    }

    &:focus {
        outline: 2px solid var(--generator-button-background);
        outline-offset: 2px;
    }

    &:hover {
        transform: scale(1.07);
    }

    &:active {
        transform: scale(0.9);
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5, .theme11,
.theme6 {
    @extend %themeBase;
}
