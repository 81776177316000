@import "../../../style/variables.scss";

%themeBase {
    position: relative;

    input {
        &:valid ~ [data-invalid-feedback] {
            display: none;
        }
    }

    :global(.invalidFeedback:not(:empty)) {
        color: $color-form-error;
        margin-top: 3px;
        font-size: 12px;
    }
}

%themeBase {
    label,
    :global(.actAsLabel) {
        margin-bottom: var(--generator-label-margin-bottom);
        display: block;
        text-align: var(--generator-label-text-align);
        font-size: var(--generator-label-font-size);
        color: var(--generator-label-color);
        font-family: var(--generator-label-font-family);
    }

    input {
        border-radius: var(--generator-textfields-radius);
        background: var(--generator-input-background);
        color: var(--generator-input-text-color);
        border: var(--generator-input-border);
        border-bottom: var(--generator-input-border-bottom);
        padding: var(--generator-input-padding);

        &:focus {
            outline: 1px solid var(--color-primary);
        }

        &:focus:read-only {
            outline: 0px; // In edit mode
        }
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5, .theme11,
.theme6 {
    @extend %themeBase;
}
