@import "../../style/variables.scss";

.button {
    border: 0px;
    display: flex;
    min-height: 30px;
    flex-wrap: wrap;
    color: #cccccc;
    align-items: center;
    justify-content: center;
    padding: 0 ($baseline);
    cursor: pointer;
    transition: $color-edit-action-transition;
    text-decoration: none;

    line-height: 11px;
    font-size: 11px;
    font-weight: bold;

    outline: none !important;

    &.highlight {
        background: #73c991;
        color: rgba(0, 0, 0, 0.8);

        &:hover {
            background: #90f6b3;
            color: rgba(0, 0, 0, 0.9);
        }
    }

    &.active {
        background: #b69860;
        background: rgba(255, 255, 255, 0.1); 
        //color: rgba(0, 0, 0, 0.8);
        //color: #9a7ebb; najs färg ändå i färgscehmat här, lila
    }

    &:hover {
        background: #4c4c4c;
        color: #fff;
    }

    //border-radius: 2px;
    background: transparent; 
    padding: ($baseline / 2) $baseline;

    flex-direction: column;

    .icon {
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 14px;

        svg {
            height: 14px;
        }
    }
    
    gap: 4px ($baseline / 2);

    .text {
        display: block;
    }

    line-height: 13px;
    font-size: 13px;

    @media (min-width: 372px) {
        flex-direction: row;
    }
}
