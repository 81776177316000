$baseline: 16px;
html {
    box-sizing: border-box;
    height: 100%;
}

html {
    font-size: 62.5%;
    body {
        font-size: 16px;
    }
}

body {
    min-height: 100%;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    line-height: 1.6;

    .modules-wrapper {
        position: relative;
    }

    img {
        max-width: 100%;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Glory", sans-serif;
    letter-spacing: 1px;
}

textarea,
select,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"] {
    width: 100%;
    padding: $baseline / 2;
    font-size: $baseline;
}
