@import "../../../style/variables.scss";

.wrapper,
.wrapperActive {
    display: flex;
    width: calc(50% - #{$baseline});
    margin: $baseline / 2;
    height: $baseline * 4;
    cursor: pointer;
    border: ($baseline / 4) solid #fff;
    border-radius: 2px;

    & > * {
        flex: 1;
    }
}

.wrapperActive {
    outline: ($baseline / 4) solid #f35284;
}
