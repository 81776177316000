@import "../../style/variables.scss";

%themeBase {
    display: flex;
    gap: var(--generator-spacing-100);
    flex-wrap: wrap;

    .halfWidth, .fullWidth{
        width: 100%;
    }
}

%themeBaseResponsive {
    @media (min-width: $breakpoint-m) {
        .halfWidth {
            width: calc(50% - var(--generator-spacing-050));
        }
    }
}

.theme5, .theme11,
.theme6 {
    @extend %themeBase;
}

.theme4,
.theme10,
.theme8,
.theme9,
.theme7 {
    @extend %themeBase;
    @extend %themeBaseResponsive;
}
