@import "../../../style/variables.scss";

.wrapper {
    cursor: pointer;
    background: #252526;
    padding: $baseline / 2;
    border-radius: 4px;
    line-height: 0;

    .text {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    &.active {
        outline: 3px solid #f35284;
    }
    
}
