@import "../../../style/variables.scss";

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5,
.theme11,
.theme6 {
    background-color: var(--color-background);
    .contentBox {
        background: var(--generator-page-wrapper-background);
        background: var(--generator-content-box-background);
        padding: ($baseline * 2) ($baseline);
        box-shadow: var(--generator-content-box-shadow);
        color: var(--generator-content-box-text-color);
    }
}
