@import "../../../../../../style/variables.scss";

.wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-s) {
        flex-direction: row;
        justify-content: space-between;
    }

    .left {
        padding: $baseline;
        display: flex;
        cursor: pointer;
        min-width: 0;
        flex: 1;
        gap: $baseline;
        align-items: center;
        transition: 0.1s ease-in background;
        
        &:hover {
            background: rgba(0, 0, 0, 0.1);
        }

        .thumbnail {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            line-height: 0;
            min-width: 50px;
            max-width: 50px;
            max-height: 50px;
        }
        .titleAndDescription {
            min-width: 0;
            flex: 1;
            .title {
                font-weight: bold;
                color: #fff;
            }
            .description {
                font-size: 14px;
                color: #ddd;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .buttons {
        display: flex;
        gap: ($baseline / 2);
        margin: 0 0 ($baseline / 2) ($baseline / 2);
        align-items: center;

        @media (min-width: $breakpoint-s) {
            margin: $baseline;
            .delete {
                order: 1;
            }
        }

        .questionText {
            font-size: 14px;
            justify-content: center;
            align-items: center;
            padding: ($baseline / 2);
        }

        button {
            background: transparent;
            white-space: nowrap;
            border: 0;
            color: $color-edit-action-text;
            font-size: 12px;
            padding: ($baseline / 2);
            font-weight: bold;
            cursor: pointer;
            display: flex;
            gap: $baseline / 2;
            justify-content: center;
            align-items: center;
            border-radius: 2px;

            outline: none !important;

            & > svg {
                height: $baseline * 1;
            }

            span {
                display: block;
            }

            &:hover {
                color: $color-edit-action-text-hover;
                background: $color-edit-action-hover;
            }
        }
    }
}
