@import "../../style/variables.scss";

.choosePaymentProviderWrapper {
    display: flex;

    .paymentProviderButton,
    .paymentProviderButtonSelected {
        width: 100%;
        background: #252526;
        color: #fff;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        flex-wrap: wrap;

        border: 2px solid #252526;
        padding: ($baseline - 2px);
        transition: border 0.3s ease-in-out;

        @media (min-width: $breakpoint-m) {
            flex-wrap: nowrap;
            justify-content: space-between;
        }

        margin-right: $baseline;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            border-color: rgba(255, 255, 255, 0.7);
        }

        span {
            font-size: 12px;
            display: block;
            letter-spacing: 1px;
            width: 100%;

            @media (min-width: 400px) {
                font-size: 14px;
            }

            @media (min-width: $breakpoint-m) {
                width: auto;
                font-size: 16px;
            }
        }

        &:focus {
            outline: none;
        }
    }

    .paymentProviderButtonSelected {
        border-color: rgba(255, 255, 255, 0.9);
    }

    .logoWrapper {
        margin-bottom: $baseline / 2;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: $breakpoint-m) {
            margin-bottom: 0;
            order: 1;
        }

        svg,
        img {
            height: $baseline * 2;
            margin-right: $baseline / 2;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
