@import "../../style/variables.scss";

.wrapper {
    label {
        font-size: 14px;
        margin-bottom: $baseline / 3;
    }
    input {
        margin-bottom: $baseline;
    }

    button {
        width: 100%;
        margin-top: $baseline / 2;
    }
}
