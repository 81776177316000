@import "../../../style/variables.scss";

.wrapper {
    padding: $baseline * 3;
    text-align: center;

    .text {
        font-size: 14px;
        margin-bottom: $baseline;
    }
}