@import "../../style/variables.scss";

@keyframes pulse {
    0% {
        transform: scale(0.9);
        opacity: 0.7;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0.9);
        opacity: 0.7;
    }
}

.loadingWrapper,
.loadingWrapperFixed {
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: $z-loading;
    color: #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .text {
        font-size: 18px;
        letter-spacing: 1px;
        padding: $baseline;
        max-width: 600px;
        text-align: center;
    }

    .icon {
        width: $baseline * 6;
        animation: pulse 2s infinite ease-in-out;
    }

    svg {
        width: 100%;
        fill: #eeeeee;
    }
}

.inverted {
    color: rgba(0, 0, 0, 0.8);
    background: #eee;

    svg {
        fill: rgba(0, 0, 0, 0.8);
    }
}

.loadingWrapperFixed {
    position: fixed;
}

.block {
    position: relative;
    background: none;
}
