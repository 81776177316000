@import "../../../../style/variables.scss";

.listWrapper {
    display: flex;
    flex-direction: column;
    gap: $baseline;
    margin-bottom: $baseline;
}

.buttonsWrapper {
    display: flex;
    gap: $baseline;
    flex-direction: column;

    @media (min-width: $breakpoint-l) {
        & > * {
            flex: 1;
        }
        flex-direction: row;
    }
}
