$baseline: 16px;

$font-standard: "Raleway", Helvetica, sans-serif;

$action-bar-height: $baseline * 3.5;
$generator-edit-menu-height: $baseline * 3;
$menu-height: $baseline * 3;
$menu-height-large: $baseline * 4;

$z-modal: 150;
$z-action-bar: 100;
$z-drawer: 110;
$z-content-module-action-bar: 10;
$z-content-module-action-bar-open: 11;
$z-menu: 20;
$z-generator-graphic: 30;
$z-menu-expanded: 40; // Needs to be higher than graphic

$z-loading: 500;
$z-error-modal: 1000;

$breakpoint-s: 500px;
$breakpoint-m: 750px;
$breakpoint-l: 980px;

$content-max-width: 1000px;
$drawer-width: 320px + $baseline;

$max-header-image-width: $content-max-width * 1.3;
$max-header-image-width-theme-1: $content-max-width + ($baseline * 6);

$color-form-error: #ff5722;
$color-link: #2196f3;

$color-edit-action: #2d2d2d;
$color-edit-action-hover: #3c3c3c;
$color-edit-action-text: #ccc;
$color-edit-action-text-hover: #fff;
$color-edit-action-transition: color 0.3s ease-out, background 0.3s ease-out;

$shadow-standard: 0 1px 3px rgba(0, 0, 0, 0.06);

$editable-outline-hover: 2px solid #f55385;
$editable-outline-focus: 2px dotted #f55385;
