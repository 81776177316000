@import "../../../style/variables.scss";

.percentageItemsWrapper {
    display: flex;
    flex-wrap: wrap;

    margin: ($baseline / 2) (-$baseline / 2);
}

.wrapper {
    display: flex;
    align-items: center;
    border: 0px solid #eee;
    border-radius: $baseline / 4;
    padding: $baseline / 2;
    flex-direction: column;
    margin: $baseline / 2;

    .percentageWrapper {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.6);
        background: #eeeeee;
        border-radius: 4px;
        height: $baseline * 3;
        width: $baseline * 8;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        position: relative;
        overflow: hidden;

        .fillerNegative,
        .fillerPositive {
            background: #8bc34a;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }

        .fillerNegative {
            background: #ff9800;
        }

        .percentage {
            position: relative;
            z-index: 1;
        }
    }

    .titleAndNumbersWrapper {
        color: #4b4b4b;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        line-height: 1em;

        .title {
            font-weight: bold;
            text-transform: uppercase;
            font-size: 12px;
            margin-bottom: 2px;
            margin-top: $baseline / 2;
        }

        .numbers {
            font-size: 14px;
        }
    }
}
