@import "../../style/variables.scss";


%themeBase {
    max-width: var(--generator-module-max-width);
    margin: 0 auto;
    padding: var(--generator-module-spacing);
    padding-bottom: var(--generator-module-spacing-bottom);

    .imageMapTextWrapper {
        .mapWrapper {
            border-radius: var(--generator-image-radius);
            box-shadow: var(--generator-content-box-shadow);
            overflow: hidden;
        }

        .imageWrapper {
            line-height: 0;
            box-shadow: var(--generator-content-box-shadow);
            img {
                border-radius: var(--generator-image-radius);
                width: 100%;
            }
        }

        .textContentWrapper {
            text-align: var(--generator-text-text-align);
            background: var(--generator-content-box-background);
            padding: var(--generator-content-box-padding-large);
            box-shadow: var(--generator-content-box-shadow);
            color: var(--generator-content-box-text-color);

            h3 {
                margin: 0 0 var(--generator-spacing-050) 0;
                font-size: var(--generator-heading-3-size);
                letter-spacing: var(--generator-heading-3-letter-spacing);
                font-family: var(--generator-heading-3-font-family);
                color: var(--generator-heading-3-color);
                font-weight: var(--generator-heading-font-weight);
            }
        }
    }
}

%themeBaseResponsive {
    .imageMapTextWrapper {
        @media (min-width: $breakpoint-m) {
            gap: var(--generator-spacing-100);
            display: grid;
            grid-template-columns: 1fr 1fr minmax(300px, 1fr);
            grid-template-rows: auto;
            grid-template-areas:
                "image image map"
                "text text text";

            .textContentWrapper {
                order: 1;
                grid-area: text;
            }

            .mapWrapper {
                grid-area: map;
            }

            .imageWrapper {
                grid-area: image;
            }
        }
    }
}

.theme5, .theme11,
.theme6 {
    @extend %themeBase;
}

.theme4,
.theme10,
.theme8,
.theme9,
.theme7 {
    @extend %themeBase;
    @extend %themeBaseResponsive;
}
