@import "../../../../style/variables.scss";

.thumbnailWrapper {
    aspect-ratio: 16 / 9;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .thumbnail {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
}
