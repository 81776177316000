@import "../../../../style/variables.scss";

.outerWrapper {
    &.THUMBNAIL {
        height: 100%;
        position: relative;
        overflow: hidden;
    }

    &.SITE {
        height: 0;
        position: sticky;
        z-index: $z-generator-graphic;
        top: 0;
        pointer-events: none;

        &.editAvailable {
            top: 56px;
        }

        .wrapper {
            .graphic1 {
                position: absolute;
                right: 0;
                overflow: hidden;
                width: 90vw;
                height: 90vh;

                @media (min-width: 1200px) {
                    right: unset;
                }
                img {
                    position: absolute;
                    right: 0;
                    margin-top: -130px;
                    z-index: $z-generator-graphic;
                    height: 300px;
                    transform: rotate(270deg);
                    width: auto;
                    max-width: unset;

                    @media (min-width: 1200px) {
                        transform: rotate(170deg);
                        height: 60vh;
                        margin-left: -7vh;
                        margin-top: 40px;
                        right: unset;
                    }
                }
            }

            .graphic2 {
                img {
                    position: fixed;
                    right: 0;
                    margin-right: Min(-10vw, -70px);
                    bottom: -2%;
                    z-index: -1;
                    width: 20vw;
                    min-width: 140px;
                }
            }
        }
    }

    &.PRINT_PAGE_1, &.PRINT_PAGE_2 {
        height: 100%;
        width: 100%;
        top: 0;
        z-index: 1;
        position: absolute;
        overflow: hidden;
        pointer-events: none;

        .wrapper {
            .graphic1 {
                position: absolute;
                right: 0;
                width: 40%;
                height: auto;
                bottom: 0;
        
                img {
                    transform: rotate(255deg);
                    margin-bottom: -110%;
                    margin-left: -60%;
                }
            }
        
            .graphic2 {
                position: absolute;
                left: 15%;
                width: 40%;
                height: auto;
                top: 0;
        
                img {
                    transform: rotate(70deg);
                    margin-top: -110%;
                }
            }
        }
    }

    &.PRINT_PAGE_2 .wrapper .graphic1 {
        display: none;
    }
}

