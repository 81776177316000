@import "../../../../../../../../style/variables.scss";

.wrapper {
    .row td,
    .headerRow > th {
        &:first-child {
            padding-left: $baseline;
        }

        &:last-child {
            padding-right: $baseline;
        }
    }

    .statusYes,
    .statusNo {
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        border-radius: 2px;
        background: #8bc34a;
        color: rgba(0, 0, 0, 0.7);
        letter-spacing: 0.5px;
        display: inline-block;
        padding: ($baseline / 4) ($baseline / 2);
        min-width: $baseline * 3;
    }

    .statusNo {
        background: #ff9800;
    }

    .deleteButton {
        background: none;
        padding: $baseline / 3;
        margin: -($baseline / 3);
        border: 0;
        cursor: pointer;
        svg {
            height: 16px;
            color: #555;
        }

        &:hover svg {
            color: #222;
        }
    }

    .headerRow > th {
        font-size: 13px;
        color: #4b4b4b;
        font-weight: bold;
        line-height: $baseline;
        vertical-align: bottom;

        .truncate {
            position: relative;
            max-height: $baseline * 3; /* exactly three lines */
            overflow: hidden;

            &:after {
                content: "";
                text-align: right;
                position: absolute;
                top: $baseline * 2;
                right: 0;
                width: 70%;
                height: 1.2em;
                background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
            }
        }
    }
}
