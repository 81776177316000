@import "../../../../../../../../../../../../../../style/variables.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    gap: $baseline * 2;
    align-items: center;

    @media (min-width: $breakpoint-m) {
        flex-direction: row;
        align-items: stretch;
    }

    .uploadImage {
        flex: 1;
        width: 100%;
    }

    .currentImageAndEditImage {
        width: 285px;

        .inner {
            display: flex;
            flex-direction: column;
            gap: $baseline;
            position: relative;

            .imageWrapper {
                line-height: 0;
                img {
                    width: 100%;
                    object-fit: contain;
                    object-position: 50% 50%;
                    border-radius: 2px;
                }
            }

            .buttonWrapper {
                position: absolute;
                bottom: 0;
                display: flex;
                justify-content: center;
                gap: $baseline;
                width: 100%;
                background: rgba(56, 56, 56, 0.4);
                backdrop-filter: blur(3px);
                padding: $baseline 0;
            }
        }

        .revertCropButton {
            margin-top: $baseline;
            display: flex;
            justify-content: center;
        }
    }
}
