@import "../../../style/variables.scss";

.wrapper,
.wrapperActive {
    width: 100%;
    margin: ($baseline) 0;
    cursor: pointer;
    background: #252526;
    padding: $baseline;
    border-radius: 4px;

    .font {
        display: none;
        font-family: $font-standard;
        font-size: 12px;
    }
}

.wrapperActive {
    padding: $baseline - ($baseline / 6);
    border: ($baseline / 6) solid #fff;

    .font {
        display: block;
    }
}
