@import "../../style/variables.scss";

.wrapper {
    .listWrapper {
        display: flex;
        flex-direction: column;
        gap: $baseline;
        margin-bottom: $baseline;
    }

    .buttonFinishWrapper {
        display: flex;
        justify-content: flex-end;
    }

    .newQuestionsWrapper {
        display: flex;
        gap: $baseline;
        flex-wrap: wrap;
    }
}
