@import "../../../../style/variables.scss";

.wrapper {
    max-width: $content-max-width;
    margin: ($baseline * 2) auto;
    padding: 0 $baseline;

    h1 {
        text-align: center;
    }

    .title {
        font-size: 20px;
        margin-top: 0;
    }

    .infoBox {
        margin-top: $baseline * 2;
        background: #fff;
        padding: $baseline * 2;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
    }


    .buttonWrapper {
        margin-top: $baseline;
    }

    .title {
        margin-top: $baseline * 2;
        &:first-child {
            margin-top: 0;
        }
    }
}
