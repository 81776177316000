$baseline: 16px;

$menu-height: $baseline * 3;
$menu-height-large: $baseline * 5;

$z-menu: 20;

$breakpoint-s: 500px;
$breakpoint-m: 750px;
$breakpoint-l: 980px;

$content-max-width: 1000px;
