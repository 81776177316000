@import "../../style/variables.scss";

.wrapper {
    .items {
        display: grid;
        gap: $baseline;
        grid-template-columns: 1fr 1fr;
    }

    .text {
        font-size: 14px;
        margin-bottom: $baseline;
    }


    .displayNoneShowInDevToolsIfNeeded {
        display: none;
    }
}
