@import "../../../style/variables.scss";

.highlighted {
    &:after {
        content: "";
        position: absolute;
        top: -4px;
        left: -4px;
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        outline: 2px dashed #f55385;
    }
}

%themeBase {
    position: relative;
    margin: calc(var(--generator-spacing-100) / 2);
    flex: 0 0 100%;

    background: var(--generator-content-box-background);
    box-shadow: var(--generator-content-box-shadow);
    padding: var(--generator-content-box-padding);

    .imageWrapper {
        position: relative;
        max-width: 220px;
        margin: 0 auto var(--generator-spacing-050) auto;

        img,
        :global(.image-placeholder) {
            text-align: center;
            position: relative;
            object-fit: cover;
            aspect-ratio: 1 / 1;
            border-radius: 50%;
            width: 100%;
        }
    }

    .textAndHeadingContentWrapper {
        text-align: center;
        color: var(--generator-content-box-text-color);

        h3 {
            margin: 0 0 0 0;
            font-size: var(--generator-heading-3-size);
            letter-spacing: var(--generator-heading-3-letter-spacing);
            font-family: var(--generator-heading-3-font-family);
            color: var(--generator-heading-3-color);
            font-weight: var(--generator-heading-font-weight);
        }

        .title {
            font-size: var(--generator-heading-3-size);
            color: var(--generator-heading-3-color-lighter);
            font-weight: var(--generator-heading-font-weight);
            letter-spacing: var(--generator-heading-3-letter-spacing);
            font-family: var(--generator-heading-3-font-family);
        }
    }
}

%themeBase {
    @media (min-width: $breakpoint-s) {
        flex: 0 0 calc(50% - var(--generator-spacing-100));
    }
}

%themeBaseResponsive {
    @media (min-width: $breakpoint-m) {
        flex: 0 0 calc(33.3333333% - var(--generator-spacing-100));
    }
}
.theme5, .theme11,
.theme6 {
    @extend %themeBase;
}

.theme4,
.theme10,
.theme8,
.theme9,
.theme7 {
    @extend %themeBase;
    @extend %themeBaseResponsive;
}

.theme4,
.theme10 {
    padding: calc(var(--generator-content-box-padding) * 0.75);
}

.theme7 {
    .textAndHeadingContentWrapper {
        text-align: center;
    }
}

.theme8 {
    .imageWrapper {
        &:not(&:empty) {
            padding-bottom: 10px;
            &::before {
                border: 4px solid var(--color-primary);
                position: absolute;
                content: "";
                width: calc(100% + 20px);
                aspect-ratio: 1 / 1;
                top: -10px;
                left: -10px;
                border-radius: 50%;
            }
        }
    }
}
