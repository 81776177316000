@import "../../style/variables.scss";

.wrapper {
    .formWrapper {
        .buttonsWrapper {
            margin-top: $baseline;

            .addMoreButtonWrapper {
                display: inline-block;
                margin-right: $baseline;
            }
        }
        .rowsWrappers {
            margin: ($baseline) (-($baseline / 2));
            display: flex;
            flex-wrap: wrap;
        }

        .rowWrapper {
            margin-bottom: $baseline;
            padding: 0 $baseline / 2;
            width: 100%;
            display: flex;

            @media (min-width: $breakpoint-m) {
                width: 50%;
            }

            .textFieldWrapper {
                flex: 1;
            }

            .removeButton {
                margin-left: $baseline * 1;
                margin-top: $baseline * 2;
                height: $baseline;
                line-height: 0;
                padding: 0;
                background: transparent;
                border: 0;
                color: #fff;
                cursor: pointer;

                svg {
                    height: $baseline;
                }
            }
        }
    }
}
