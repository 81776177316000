@import "../../style/variables.scss";

%themeBase {
    max-width: var(--generator-module-max-width);
    margin: 0 auto;
    padding: var(--generator-module-spacing);
    padding-bottom: var(--generator-module-spacing-bottom);

    .textContentWrapper {
        background: var(--generator-content-box-background);
        box-shadow: var(--generator-content-box-shadow);
        text-align: var(--generator-text-text-align);
        color: var(--generator-content-box-text-color);
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5, .theme11,
.theme6 {
    @extend %themeBase;
}


.theme4 {
    .textContentWrapper {
        padding: var(--generator-content-box-padding);
    }
}
