@import "../../../../../../../../../../../../style/variables.scss";

.wrapper {
    text-align: center;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    line-height: 1.6;
    position: relative; // Needed for graphics one level up

    font-family: var(--font-text);
    color: var(--color-on-background);

    font-size: 2.8cqw;
    letter-spacing: 0.1cqw;

    &.page1 {
        position: relative;
        .imageWrapper {
            width: 100%;
            max-width: 100%;
            overflow: hidden;
            .primaryImage {
                line-height: 0;
                height: 100%;
                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .title {
            color: #fff;
            position: absolute;
            bottom: 10%;
            font-size: 11cqw;
            line-height: 1.2;
            padding: 4.5cqw 6cqw 4cqw 6cqw;
            font-family: var(--font-heading);
            background: rgba(0,0,0,0.6);
            width: 100%;
        }

    }

    &.page2 {
        align-items: center;
        .qr {
            width: 30cqw;
            line-height: 0;
            margin: 0 auto;

            .img {
                border: 1cqw solid white;
                border-radius: 2px;
            }
        }

        .textAtQR {
            margin-bottom: 3cqw;
            padding: 0 6cqw;
        }
    }
}
