@import "../../../../../../style/variables.scss";

.wrapper {
    .cardElementWrapper {
        background: #fff;
        padding: $baseline;
        border-radius: 3px;
        margin-top: 2px;
        margin-bottom: $baseline;
    }

    .label {
        font-size: 14px;
    }

    .errors {
        color: $color-form-error;
        margin-bottom: $baseline;
    }

    .buttonAndLogosWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (min-width: $breakpoint-s) {
            flex-wrap: nowrap;
        }

        .logosWrapper {
            height: 34px;
            display: flex;
            align-items: center;
            line-height: 0;
            width: 100%;
            margin-top: $baseline;

            @media (min-width: $breakpoint-s) {
                width: auto;
                margin-top: 0;
            }
        }
    }
}
