@import "../../../../../../style/variables.scss";

.smallScreenInfoImageSizesV2 {
    display: block;
    text-align: center;
    margin-top: $baseline / 2;

    font-size: 12px;
    @media (min-width: 1000px) {
        display: none;
    }
}

.sizeWrapper {
    display: flex;
    margin-top: $baseline;
    flex-wrap: wrap;

    @media (min-width: $breakpoint-s) {
        flex-wrap: nowrap;
        gap: ($baseline / 2);
        justify-content: stretch;
    }

    .size,
    .sizeActive {
        margin: ($baseline / 2) 0;
        cursor: pointer;
        background: #252526;
        padding: $baseline;
        border-radius: 4px;
        text-align: center;
        width: 100%;

        .tooSmallScreenInfo {
            display: block;

            font-size: 12px;
            @media (min-width: 400px) {
                display: none;
            }
        }

        .tooSmallScreenInfoMediumSize {
            display: none;
            font-size: 12px;
            @media (max-width: 968px) and (min-width: 400px) {
                display: block;
            }
        }

        @media (min-width: $breakpoint-s) {
            flex: auto;
            width: auto;
        }
    }

    .sizeActive {
        padding: $baseline - ($baseline / 6);
        border: ($baseline / 6) solid #fff;
    }
}
