@import "../../style/variables.scss";

.wrapperPrimary {
    background: $color-edit-action;
    position: sticky;
    top: 0px;
    left: 0;
    width: 100%;
    height: $action-bar-height;
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 0;
    z-index: $z-action-bar;
    padding: 0 ($baseline / 2);

    @media (min-width: 372px) {
        padding: 0 $baseline;
    }
}

.items {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: center;

    gap: $baseline / 2;

    @media (min-width: 372px) {
        justify-content: center;
        align-items: center;
    }

    &:not(.multipleRows) {
        @media (min-width: 420px) {
            gap: $baseline;
        }
    }

    &.multipleRows {
        flex-wrap: wrap;
    }
}

.wrapper {
    padding: $baseline / 2;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.1);
}
