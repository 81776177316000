@import "../../../style/variables.scss";

.wrapper {
    padding: ($baseline * 3) $baseline * 1;
    background: white;
    text-align: center;
    max-width: 500px;
    width: 100%;
    color: #000;
    border-radius: 3px;
    min-width: 300px;
    font-family: var(--font-text);

    .titleWrapper {
        margin-bottom: $baseline / 2;

        h1 {
            font-size: 24px;
            margin: 0;
        }
    }
}