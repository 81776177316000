@import "../../../../../../style/variables.scss";

.wrapper {
    .editButton {
        margin-bottom: $baseline;
        display: flex;
        justify-content: center;
    }
}

.modal {
    label {
        font-size: 14px;
        margin-bottom: $baseline / 3;
    }
    input {
        margin-bottom: $baseline;
    }

    button {
        width: 100%;
        margin-top: $baseline * 2;
    }

    .selectWrapper {
        display: flex;
        flex-wrap: wrap;
        gap: $baseline;
        margin-top: $baseline / 2;

        & > * {
            flex: 1;
            min-width: 300px;
        }
    }
}
