@import "../../style/variables.scss";

%themeBase {
    max-width: var(--generator-module-max-width);
    margin: 0 auto;
    padding: var(--generator-module-spacing);
    padding-bottom: var(--generator-module-spacing-bottom);
    .itemsWrapper {
        display: flex;
        flex-wrap: wrap;
        margin: calc(var(--generator-spacing-100) / 2 * -1);
        justify-content: center;
    }
    position: relative;
}

.editButtonWrapper {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: $baseline / 2;
    @media (min-width: $breakpoint-m) {
        bottom: $baseline * 2;
    }
    left: 50%;
    transform: translate(-50%, 0);
}

.theme5, .theme11,
.theme6 {
    @extend %themeBase;
}

.theme4,
.theme10,
.theme8,
.theme9,
.theme7 {
    @extend %themeBase;
}
