@import "../../../../../../../../../../../../../../../../style/variables.scss";

.wrapper {
    .ratioWrapper {
        margin-bottom: $baseline;
    }

    .cropWrapper {
        display: flex;
        justify-content: center;
        .image {
            max-height: calc(100vh - 300px);
        }
    }

    .buttons {
        margin-top: $baseline;
        justify-content: right;
        display: flex;
        gap: $baseline;
    }
}
