@import "../../style/variables.scss";

.wrapper {
    .buttonWrapper {
        margin-top: $baseline;
    }

    .termsWrapper {
        font-size: 14px;
    }

    .readTermsLink {
        color: #fff;
        text-decoration: underline;
        display: inline-block;
        font-size: 14px;
    }
}

.termsCloseButtonWrapper {
    text-align: center;
}
