@import "../../style/variables.scss";

%themeBase {
    max-width: var(--generator-module-max-width);
    margin: 0 auto;
    padding: var(--generator-module-spacing);
    padding-bottom: var(--generator-module-spacing-bottom);
    position: relative;
    .itemsWrapper {
        display: grid;
        gap: var(--generator-spacing-200);
    }
}

.editButtonWrapper {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: $baseline / 2;
    @media (min-width: $breakpoint-m) {
        bottom: $baseline * 2;
    }
    left: 50%;
    transform: translate(-50%, 0);
}

%themeBaseResponsive {
    .itemsWrapper {
        @media (min-width: $breakpoint-m) {
            grid-template-columns: 1fr 1fr;
        }
    }
}

.theme5, .theme11,
.theme6 {
    @extend %themeBase;
}

.theme4,
.theme10,
.theme8,
.theme9,
.theme7 {
    @extend %themeBase;
    @extend %themeBaseResponsive;
}
