@import "../../style/variables.scss";

.wrapper {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    a {
        word-break: break-all;
    }

    max-width: 100%;

    p {
        margin: 0;

        // Fix so that empty p-tags will still take a line as in RTE.
        &:empty:before {
            content: " ";
            display: inline-block;
            white-space: pre-wrap;
        }

        // This should be cleaned from output instead but this is easier right now.
        // Example: <p><strong></strong></p> or <p><strong><em></em></strong></p>
        &,
        & > strong,
        & > em,
        & > u {
            & > strong,
            & > em,
            & > u {
                &:only-child:empty:before {
                    content: " ";
                    display: inline-block;
                    white-space: pre-wrap;
                }
            }
        }
    }
}

