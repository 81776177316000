@import "../../style/variables.scss";

.mapWrapper {
    height: 100%;
    max-height: 100%;
    width: 100%;
    position: relative;
    min-height: 200px;
    overflow: hidden;
    display: block;

    img {
        object-fit: cover;
        min-height: 640px;
        min-width: 640px;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        position: absolute;
    }

    .locationLabel {
        position: absolute;
        top: $baseline;
        left: $baseline;
        z-index: 1;
        box-shadow: $shadow-standard;
        padding: $baseline / 2;
        font-size: 14px;
        line-height: $baseline;
        font-weight: bold;
        letter-spacing: 0.5px;

        background: hsla(var(--color-surface-h, 0), var(--color-surface-s, 0%), var(--color-surface-l, 100%), 0.7);
        color: var(--color-on-surface, #353535);
    }
}
