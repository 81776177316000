@import "../../../../style/variables.scss";

.fontSetter {
    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: var(--font-heading);
    }

    font-family: var(--font-text);
}
