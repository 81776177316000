@import "../../style/variables.scss";

.Overlay {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
	position: fixed;
	inset: 0;
	animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: $z-modal;
	display: grid;
	place-items: center;
}

.content {
	border-radius: 0px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;

    padding: ($baseline * 2) ($baseline * 1);
    @media (min-width: $breakpoint-s) {
        width: 90vw;
        border-radius: 6px;
        padding: ($baseline * 2) ($baseline * 2);
    }

    max-width: $content-max-width;
    max-height: calc(100% - #{$baseline * 4});
	animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

	&:focus {
		outline: none;
	}
    z-index: $z-modal;
    background: #383838;
    overflow-y: auto;
    color: #eeeeee;

	&.small {
		max-width: 500px;
	}

	.close {
		position: absolute;
		top: $baseline / 2;
		right: $baseline / 2;
		background: none;
		border: 0;
		color: #FFF;
		z-index: 1;
		padding: $baseline / 2;
		margin: 0;
		line-height: 0;
		cursor: pointer;

		svg {
			height: $baseline;
		}
	}
}


@keyframes overlayShow {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes contentShow {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}