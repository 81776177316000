@import "../../style/variables.scss";

.wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: -($baseline / 2);

    .secondary {
        margin: $baseline / 2;
    }

    .primary {
        margin: $baseline / 2;
    }
}
