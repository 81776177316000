@import "../../style/variables.scss";

.editable {
    overflow-wrap: break-word;

    &:empty:before {
        content: attr(placeholder);
        color: rgba(0, 0, 0, 0.4);
    }
}

.editWrapper {
    position: relative;

    .moveButtonUp, .moveButtonDown {
        position: absolute;
        right: 100%;

        .text {
            width: 25px;
            text-align: left;
        }

        @media (max-width: 600px) {
            .text {
                display: none;
            }
        }
    }

    .moveButtonUp {
        bottom: calc(50% + 2px);
    }

    .moveButtonDown {
        top: calc(50% + 2px);
    }
}

.placeholder {
    background: #eee;
    width: 100%;
    min-height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $baseline * 2;
    cursor: pointer;

    &:focus {
        outline: $editable-outline-focus;
    }

    &:hover {
        outline: $editable-outline-hover;
    }
}
