@import "../../../style/variables.scss";


.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
	gap: $baseline / 2;

	&.labelRight {
		justify-content: flex-start;
		.label {
			margin-left: $baseline / 2;
			order: 1;
		}
	}
}
.Root {
	all: unset;
	cursor: pointer;
	width: 42px;
	height: 25px;
	background-color: #616161;
	border-radius: 9999px;
	position: relative;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	&:focus {
        outline: 2px solid #828282;
        outline-offset: 1px;
	}
	&[data-state="checked"] {
        background-color: #65b07f;

        &:focus {
            outline: 2px solid #487e5b;
        }
	}
}

.Thumb {
	display: block;
	width: 21px;
	height: 21px;
	background-color: rgba(255,255,255,0.95);
	border-radius: 9999px;
	//box-shadow: 0 2px 2px var(--black-a7);
	transition: transform 100ms;
	transform: translateX(2px);
	will-change: transform;
	&[data-state="checked"] {
		transform: translateX(19px);
	}
}