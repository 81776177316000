@import "../../../../style/variables.scss";

.wrapper {

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .left {
        padding: $baseline;
        display: flex;
        cursor: pointer;
        min-width: 0;
        flex: 1;
        gap: $baseline;
        align-items: center;
        transition: 0.1s ease-in background;

        &:hover {
            background: rgba(0, 0, 0, 0.1);
        }

        border-right: 1px solid #3b3b3b;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        .titleAndDescription {
            min-width: 0;
            flex: 1;
            .title {
                font-weight: bold;
                color: #fff;
                font-size: 14px;
            }
            .description {
                font-size: 13px;
                color: #b1b1b1;
                display: flex;
                gap: 0 ($baseline / 2);
                flex-wrap: wrap;
            }
        }
    }

    .buttons, .deleteConfirmation {
        display: flex;
        gap: ($baseline / 2);
        align-items: center;
        margin: $baseline / 2;
        justify-content: flex-end;

        .questionText {
            font-size: 14px;
            justify-content: center;
            align-items: center;
            padding: ($baseline / 2);
            @media (max-width: $breakpoint-s) {
                padding-bottom: 0;
            }
        }

        button {
            background: transparent;
            white-space: nowrap;
            border: 0;
            color: $color-edit-action-text;
            font-size: 12px;
            padding: ($baseline / 2);
            font-weight: bold;
            cursor: pointer;
            display: flex;
            gap: $baseline / 2;
            justify-content: center;
            align-items: center;
            border-radius: 2px;

            outline: none !important;

            & > svg {
                height: $baseline * 1;
            }

            @media (max-width: $breakpoint-s) {
                .text {
                    display: none;
                }
            }

            span {
                display: block;
            }

            &:hover {
                color: $color-edit-action-text-hover;
                background: $color-edit-action-hover;
            }
        }
    }


    .deleteConfirmation {
        @media (max-width: $breakpoint-s) {
        flex-direction: column;
        }
        
    }
}
