@import "../../../../style/variables.scss";

.wrapper {
    margin-top: $baseline * 2;
    .paymentInfo {
        font-size: 14px;
    }

    .buttonWrapper {
        margin-top: $baseline / 2;
    }

    .validationMessage {
        display: block;
        color: #ff9800;
        margin-bottom: $baseline;
        font-size: 16px;
        width: 100%;
    }

    .pollingWrapper {
        text-align: center;
        .openAppText {
            font-weight: bold;
            margin-bottom: $baseline * 2;
            color: #f35284;
        }

        i {
            font-size: 14px;
            display: block;
            margin-bottom: $baseline * 2;
            margin-top: $baseline;
        }
    }
}
