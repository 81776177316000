@import "../../../style/variables.scss";

.wrapper {
    display: flex;
    margin: 0;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -10px;

    .switchAndTextWrapper {
        display: flex;
        align-items: center;
    }

    .switch {
        width: $baseline;
        height: $baseline;
        margin: 0;
        padding: 0;
        margin-right: $baseline / 3;
    }
}
