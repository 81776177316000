@import "../../style/variables.scss";

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: $z-modal;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;

    &:before {
        content: "";
        position: absolute;
        z-index: $z-modal;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
    }

    .innerWrapper {
        background: #383838;
        position: relative;
        z-index: $z-modal;
        width: 100%;
        color: #eeeeee;
        //box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 0;
        padding: ($baseline * 2) ($baseline * 1);
        display: block;
        max-width: $content-max-width;
        overflow-y: auto;
        max-height: calc(100% - #{$baseline * 4});

        @media (min-width: $breakpoint-s) {
            margin: $baseline;
            border-radius: 4px;
            padding: ($baseline * 2) ($baseline * 2);
        }

        .top {
            display: flex;
            margin-bottom: $baseline;
            justify-content: space-between;

            .title {
                font-weight: bold;
            }
        }
    }
}
