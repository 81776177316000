@import "../../style/variables.scss";

.yesOrNoGroup {
    display: flex;
    flex-wrap: wrap;
    //margin: ($baseline / 2) 0;

    .yesOrNoWrapper {
        width: 100%;
        position: relative;

        .addNewYesOrNoInactive {
            filter: blur(2px);
            opacity: 0.8;
        }

        .addNewYesOrNoButton {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            margin: 0 auto ($baseline) auto;
        }
    }
}
