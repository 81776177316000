@import "../../../../style/variables.scss";

.wrapper {
    max-width: $content-max-width;
    margin: ($baseline * 2) auto;
    padding: 0 $baseline;
    text-align: center;

    .section {
        display: flex;
        flex-direction: column;
        gap: $baseline * 2;
        margin-top: $baseline * 2;
    }

    .designTemplates {
        display: grid;
        column-gap: $baseline * 2;
        row-gap: $baseline * 2;

        grid-template-columns: 1fr 1fr;

        @media (min-width: $breakpoint-m) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (min-width: $breakpoint-l) {
            grid-template-columns: 1fr 1fr 1fr ;
        }
    }
}
