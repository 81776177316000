@import "../../style/variables.scss";

.background {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: $z-error-modal;
}

.wrapper {
    background: #fff;
    position: fixed;
    top: $baseline * 2;
    left: calc(50% - 160px);
    width: 320px;
    z-index: $z-error-modal + 1;
    overflow-y: auto;
    max-height: calc(100vh - #{$action-bar-height});
    padding: $baseline;
    display: block;

    @media (min-width: $breakpoint-s) {
        left: calc(50% - 250px);
        width: 500px;
        margin-top: $baseline * 4;
    }

    .innerWrapper {
        margin: 0 auto;
        max-width: $content-max-width;

        .top {
            display: flex;
            margin-bottom: $baseline;
            justify-content: space-between;

            .title {
                font-weight: bold;
            }
        }

        .message {
            padding-bottom: $baseline;
        }
    }
}
