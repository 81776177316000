@import "../../../../../../../../style/variables.scss";

.wrapper {
    .graphsWrapper {
        display: flex;
        gap: $baseline;
        justify-content: stretch;
        flex-wrap: wrap;
        margin-bottom: $baseline * 2;

        .graph {
            flex: 1;
            min-width: 260px;
            background: #fff;
            padding: $baseline;
            border-radius: 4px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);

            .question {
                font-size: 13px;
                color: #4b4b4b;
                font-weight: bold;
            }

            .answers {
                margin-top: $baseline / 4;
                display: grid;
                grid-template-columns: auto 1fr;
                align-items: center;
                font-size: 14px;
                gap: $baseline / 4;
                .text {
                    color: #4b4b4b;
                }

                .count {
                    margin-right: $baseline / 2;
                }
            }
        }
    }
}
