@import "../../../../../../style/variables.scss";

.wrapper {
    h3 {
        margin-top: 0;
        margin-bottom: $baseline;
        letter-spacing: 1px;

        font-size: 22px;

        @media (min-width: $breakpoint-m) {
            font-size: 26px;
        }
    }

    .textFieldsWrapper {
        margin-bottom: $baseline * 2;

        .textFieldPassword {
            margin-bottom: $baseline;
        }

        .textFieldQuantity {
            margin-bottom: $baseline;
        }
    }

    .quantity {
        margin-bottom: $baseline;
    }
}
