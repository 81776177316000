@import "../../../../../style/variables.scss";

.wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-m) {
        flex-direction: row;

        .formWrapper {
            flex: 2;
        }

        .imageEditWrapper {
            flex: 1;
            padding-left: $baseline * 2;
        }
    }

    .formFieldWrapper,
    .formFieldWrapperReservableAndQuantity {
        margin-bottom: $baseline;
    }

    .formFieldWrapperReservableAndQuantity {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: $baseline * 4;

        .reservableWrapper {
            margin-right: $baseline;
        }

        .quantityWrapper {
            width: 100px;
            @media (min-width: $breakpoint-s) {
                width: 150px;
            }

            @media (min-width: $breakpoint-m) {
                width: 200px;
            }
        }
    }

    .imageWrapper {
        margin-top: $baseline;

        img {
            width: 100%;
            height: $baseline * 8;
            object-fit: cover;
            object-position: 50% 50%;
            border-radius: 2px;
        }
    }

    .deleteImageButton {
        margin-top: $baseline;
    }
}

.bottomButtons {
    margin-top: $baseline;
    display: flex;
    justify-content: flex-end;

    .doneButton {
        flex: 1;
        margin-left: $baseline;
        max-width: 200px;
    }

    .deleteButton {
    }
}
