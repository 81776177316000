@import "../../../../style/variables.scss";

%themeBase {
    max-width: var(--generator-module-max-width);
    margin: 0 auto;
    padding: var(--generator-module-spacing);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.editable {
        cursor: pointer;
        &:focus {
            outline: $editable-outline-focus;
        }

        &:hover {
            outline: $editable-outline-hover;
        }
    }

    & > .col {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin: $baseline / 2;
        padding: 8px 0;
        flex: 1;
        color: var(--color-on-background);

        strong {
            font-weight: bold;
            margin-bottom: $baseline / 2;
            font-size: var(--generator-heading-2-size);
            font-family: var(--font-heading);
            width: 100%;
            text-align: center;
        }

        span {
            font-size: clamp(12px, 1.2rem, 14px);
            letter-spacing: 0.1rem;
        }
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5,
.theme11,
.theme6 {
    @extend %themeBase;
}
.theme9 {
    background: var(--color-primary);

    --color-on-background: var(--color-on-primary);
}

.theme11 {
    & > .col {
        strong {
            font-weight: normal;
        }
    }
}
