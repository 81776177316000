@import "../../style/variables.scss";

.colors {
    display: flex;
    margin: 0 (-$baseline / 2) $baseline (-$baseline / 2);
    flex-wrap: wrap;
}

.advanced {
    display: flex;
    flex-wrap: wrap;
}

.displayNoneShowInDevToolsIfNeeded {
    display: none;
}
