@import "../../style/variables.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    gap: $baseline;

    a.currentLink {
        color: #FFF;
    }
}
