@import "../../../../../../style/variables.scss";

.wrapper {
    .currentStatus {
        font-size: 14px;

        &.warning {
            color: #e9796b;
        }
    }

    .selectWrapper {
        margin-top: $baseline;
        display: flex;
        flex-direction: column;
        gap: $baseline;
    }
}