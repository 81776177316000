@import "../../style/variables.scss";

.editable {
    overflow-wrap: break-word;

    &:empty:before {
        content: attr(placeholder);
        color: rgba(0, 0, 0, 0.4);
    }
}
