@import "../../../../../../../../style/variables.scss";

.wrapper {
    background: #fff;
    padding: $baseline;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
    width: 100%;

    .sectionTitle {
        font-family: $font-standard;
        margin: 0 0 ($baseline) 0;
        letter-spacing: 0;
        text-align: center;
    }

    @media (min-width: $breakpoint-m) {
        padding: $baseline * 1.5;
    }

    @media (min-width: $breakpoint-m) {
        grid-column: 2 span;
    }

    .cardWrappers {
        display: flex;
        gap: $baseline;
        flex-wrap: wrap;
        justify-content: space-around;
        .cardWrapper {
            text-decoration: none;
            width: 200px;
        }
    }
}
