@import "../../../../style/variables.scss";

%themeBase {
    display: flex;
    flex-direction: column;
    padding: $baseline / 2; // For hover edit effect
    position: relative;

    .imageWrapper {
        margin-bottom: $baseline / 2;
        cursor: pointer;
        img {
            width: 100%;
            height: $baseline * 8;
            object-fit: cover;
            object-position: 50% 50%;
            border-radius: 2px;
        }
    }

    .title {
        margin: 0;
        margin-bottom: 0px;
        font-size: 16px;
        font-weight: bold;
        font-family: var(--font-text);
        letter-spacing: normal;
    }

    .quantity {
        font-size: 16px;
    }

    .linkWrapper {
        .link {
            display: flex;
            align-items: center;
            text-decoration: none;

            svg {
                height: 12px;
                margin-right: $baseline / 4;
            }

            .linkText {
                font-size: 16px;
                width: 200px;
                @media (min-width: $breakpoint-m) {
                    width: 300px;
                }
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .description {
        white-space: pre-line;
        font-size: 14px;
        line-height: $baseline * 1.5;
        letter-spacing: 0.5px;
        margin-top: $baseline / 2;
    }

    .reserveButtons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: $baseline / 2;

        & > div {
            margin-right: $baseline;
            padding-top: $baseline / 2;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    @media (min-width: $breakpoint-s) {
        flex-direction: row;

        .contentWrapper {
            flex: 2;
            padding-right: $baseline;
        }
        .imageWrapper {
            flex: 1;
            order: 1;
        }
    }
}


%themeBase {
    .imageWrapper img {
        border-radius: var(--generator-image-radius);
    }
    border-top: var(--generator-border-divider-trivial);
    padding: var(--generator-spacing-150) 0;
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5,
.theme6, .theme11 {
    @extend %themeBase;
}

.theme4,
.theme10 {
    border-top: unset;
}
