@import "../../style/variables.scss";

$drawerCoversContent: ($content-max-width + $drawer-width); // 1336px,  Also used in drawer.tsx!

.wrapper {
    position: relative;
    display: flex;

    .drawerWrapper {
        width: 0;
        background: #2d2d2d;
        transition: width 0.3s ease-in;
        @media (min-width: $drawerCoversContent) {
            &[data-open-drawer] {
                width: $drawer-width;
            }
        }
        position: relative;
        overflow: hidden;
        /*
    @media (max-width: $drawerCoversContent - 1px) {
      &:global(.open:before) {
          content: "";
          z-index: $z-drawer;
          position: fixed;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: rgba(0, 0, 0, 0.5);
      }
    }
    */
    }

    .content {
        flex: 1;
        max-width: 100%;
    }
}
