@import "../../style/variables.scss";

%themeBase {
    padding: var(--generator-module-heading-padding);
    hr {
        height: 0;
        border: 0;

        &:before {
            border-top: var(--generator-border-divider);
            content: "";
            width: 100%;
            top: 0;
            opacity: var(--generator-border-divider-opacity);
            left: 0;
            position: absolute;
        }
    }

    h2 {
        text-align: var(--generator-heading-text-align);
        color: var(--generator-heading-color);
        position: relative;
        padding: 0;
        margin: 0;
        font-size: var(--generator-heading-2-size);
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5,
.theme11,
.theme6 {
    @extend %themeBase;
}

.theme10 {
    text-align: center;

    h2 {
        display: inline-block;
        background: var(--color-primary);
        color: var(--generator-heading-color);
        letter-spacing: 2px;
        padding: var(--generator-spacing-025) var(--generator-spacing-200);
        clip-path: polygon(
            100% 0%,
            calc(100% - var(--generator-spacing-050)) 50%,
            100% 100%,
            0 100%,
            var(--generator-spacing-050) 50%,
            0 0
        );
    }
}

.theme11 {
    hr {
        &:before {
            border: 0;
            height: 1px;
            background-image: linear-gradient(
                to right,
                rgba(0, 0, 0, 0),
                hsla(
                    var(--color-on-surface-h),
                    var(--color-on-surface-s),
                    var(--color-on-surface-l),
                    calc(var(--color-on-surface-a) * 0.7)
                ),
                rgba(0, 0, 0, 0)
            );
        }
    }
}
