@import "../../style/variables.scss";

.wrapper {
    border: 1px dashed #9e9e9e;
    border-radius: 3px;
    display: block;
    padding: $baseline * 2;
    cursor: pointer;
    text-align: center;
    margin-top: 2px;
    margin-bottom: 2px;
    transition: background 0.3s ease-out;

    svg {
        margin: 0 auto ($baseline / 2) auto;
        width: $baseline * 3;
    }
    &:hover {
        border: 1px solid #9e9e9e;
        background: rgba(0, 0, 0, 0.2);
    }
}

.isDragActive {
    border: 1px solid #9e9e9e;
    color: #ffb607;
    background: rgba(0, 0, 0, 0.2);

    svg {
        color: #ffb607;
    }
}
