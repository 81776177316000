@import "../../style/variables.scss";

$header-background: rgba(45, 45, 45, 0.97);

.spacer {
    height: $menu-height;
    background: #2d2d2d;

    @media (min-width: $breakpoint-l) {
        height: $menu-height-large;
    }
}

.wrapper {
    background: $header-background;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $menu-height;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0;
    z-index: $z-menu;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);

    @media (min-width: $breakpoint-l) {
        height: $menu-height-large;
    }

    .innerWrapper {
        display: flex;
        max-width: $content-max-width;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .logotypeWrapper {
        position: relative;
        flex: 1;
        z-index: 1;
        display: flex;
        padding: 0 ($baseline);
        padding-right: $baseline / 2;
        align-items: center;
        height: $menu-height;

        svg {
            height: $baseline;
            fill: #f6bd60;
        }

        text-decoration: none;

        .logotypeText {
            text-decoration: none;
            font-size: 12px;
            margin-left: $baseline / 2;
            letter-spacing: 1px;
            color: #f6bd60;
            font-family: "Raleway", serif;
        }

        @media (min-width: $breakpoint-s) {
            .logotypeText {
                font-size: $baseline;
                letter-spacing: 2px;
            }

            svg {
                height: $baseline * 1.5;
            }
        }

        @media (min-width: $breakpoint-l) {
            .logotypeText {
                font-size: 20px;
            }

            svg {
                height: $baseline * 2;
            }
        }
    }

    .menu {
        display: flex;
        margin-right: $baseline / 2;

        a {
            text-decoration: none;
            background: transparent;
            color: #dcdcdc;
            border: 0;
            display: flex;
            align-items: center;
            font-size: $baseline - 4;
            padding: 8px;
            cursor: pointer;
            position: relative;

            @media (min-width: $breakpoint-s) {
                font-size: $baseline - 2;
                padding: 12px;
                letter-spacing: 1px;
            }

            @media (min-width: $breakpoint-m) {
                padding: $baseline;
                font-size: $baseline;
            }

            .hideWhenVerySmall {
                @media (max-width: 360px) {
                    display: none;
                }
            }

            outline: none !important;

            .inner {
                border-top: 1px solid transparent;
                border-bottom: 1px solid rgba(220, 220, 220, 0);
                transition: border 300ms ease-out;
            }

            &:hover {
                .inner {
                    border-top: 1px solid transparent;
                    border-bottom: 1px solid rgba(220, 220, 220, 0.4);
                }
            }

            &.active {
                .inner {
                    border-top: 1px solid transparent;
                    border-bottom: 1px solid rgba(220, 220, 220, 0.8);
                }
            }
        }
    }
}
