@import "../../../style/variables.scss";

.wrapper,
.wrapperActive {
    cursor: pointer;
    background: #252526;
    padding: $baseline / 2;
    border-radius: 4px;
    line-height: 0;
    aspect-ratio: 1 / 1;
    width: calc(50% - #{$baseline / 2});

    .text {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}

.wrapperActive {
    outline: 3px solid #f35284;
}
