@import "../../../style/variables.scss";

.item {
    width: 100%;
    margin: ($baseline / 2) 0;
    cursor: pointer;
    background: #eeeeee;
    border: 2px solid #eeeeee;
    padding: $baseline / 2;
    border-radius: 4px;
    transition: border-color 0.3s ease-in-out;
    font-size: $baseline;
    line-height: $baseline * 1.5;

    &.selected {
        border-color: rgba(0, 0, 0, 0.2);
    }

    &:not(.selected):hover {
        border-color: rgba(0, 0, 0, 0.1);
    }

    &.dark {
        border-color: #252526;
        background: #252526;
        color: $color-edit-action-text;

        &.selected {
            border-color: rgba(255, 255, 255, 0.8);
        }

        &:not(.selected):hover {
            border-color: rgba(255, 255, 255, 0.5);
        }
    }
}
