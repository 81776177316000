@import "../../../../../../../../../../../../../../style/variables.scss";

.editMode {
    cursor: pointer;
    &:hover {
        outline: $editable-outline-hover;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: $baseline;
}