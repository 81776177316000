@import "../../style/variables.scss";

.editableWrapper {
    height: 100%;
    cursor: pointer;

    &:focus {
        outline: $editable-outline-focus;
    }

    &:hover {
        outline: $editable-outline-hover;
    }

    .placeholder {
        background: #cccccc;
        text-align: center;
        padding: $baseline;
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: $baseline;
        color: rgba(0, 0, 0, 0.8);

        svg {
            width: $baseline * 2;
            margin-bottom: $baseline / 2;
        }

        .helpText {
            font-size: 12px;
            margin-top: $baseline / 2;
        }
    }
}

.mapWrapper {
    min-height: 200px;
    height: 100%;
}
