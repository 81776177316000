@import "../../../../style/variables.scss";

.wrapper {

    display: flex;
    gap: $baseline;
    flex-direction: column;

    @media (min-width: $breakpoint-m) {
        flex-direction:row;
    }

    .column {
        flex: 1;
        display: flex;
        gap: $baseline;
        flex-direction: column;
    }

    .section, .questionSection  {
        padding: $baseline;
        background: #252526;
        border-radius: 4px;
        display: flex;
        align-items: flex-start;

        & >  * {
            flex: 1;
        }

        &.noPadding {
            padding: 0;
        }
    }
    
    .questionSection {
        flex-direction: column;
        gap: $baseline;

        & > * {
            width: 100%;
        }
    }
}
