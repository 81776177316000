@import "../../../../style/variables.scss";
.background {
    &.SITE {
        z-index: -1;
        position: fixed;
        overflow-y: scroll; // iOS hack to avoid scroll issue with fixed image background
        -webkit-overflow-scrolling: touch; // iOS hack to avoid scroll issue with fixed image background
        width: 100vw;
        height: 100vh;
        height: 100lvh;
    }

    &.THUMBNAIL {
        width: 100%;
        height: 100%;
        position: relative;
    }

    &.PRINT_PAGE_1,
    &.PRINT_PAGE_2 {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    margin: 0;

    // The entire div in div is also a iOS hack to avoid scroll issue with fixed image background

    .image {
        width: 100%;
        height: calc(100% + 1px); // iOS hack
        background-size: cover;
        background-position: top;
    }

    &.BACKGROUND_IMAGE_FLOWERS_CLEAN {
        .image {
            background-position: bottom;
            background-size: contain;
            background-repeat: no-repeat;
        }

        &.SITE .image {
            @media (max-width: $breakpoint-m) {
                background-position: bottom left;
                background-size: 200%;
                background-repeat: no-repeat;
            }
        }
    }

    &.BACKGROUND_IMAGE_BLURRY_DECORATIONS .image {
        background-position: center;
        opacity: 0.3;
    }

    &.BACKGROUND_IMAGE_BOKEH_DARK .image {
        background-position: center;
        opacity: 0.2;
    }
    &.BACKGROUND_IMAGE_BOKEH_FIREWORKS .image {
        background-position: center;
        opacity: 0.2;
    }
    &.BACKGROUND_IMAGE_BOKEH_LIGHT .image {
        background-position: bottom;
        opacity: 0.7;
    }
    &.BACKGROUND_IMAGE_DANCING_PARTY .image {
        background-position: center;
        opacity: 0.4;
    }

    &.BACKGROUND_IMAGE_NOISE .image {
        background-repeat: repeat;
        background-size: auto;
    }
}
