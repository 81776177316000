@import "../../../../../../style/variables.scss";

.wrapper {
    position: absolute;
    bottom: 100%;
    width: calc(100% + #{$baseline});
    display: none;
    border-radius: 2px;
    margin-left: -(($baseline / 2) + 1);
    margin-right: -(($baseline / 2) + 1);
    flex-wrap: wrap;

    background: #252526;
    opacity: 0.9;
    color: rgba(255, 255, 255, 0.8);
    padding: 0 ($baseline / 2);

    &.show {
        display: flex;
    }

    @media (min-width: 400px) {
        .spacer {
            width: ($baseline / 2);
        }
    }

    .iconUnderlined {
        line-height: 0;
        margin-bottom: -1px;

        svg {
            height: 15px;
        }
    }
}
