@import "../../../style/variables.scss";

.wrapper {
    .label {
        margin-bottom: 2px;
    }

    &.light {
        .select {
            border: 1px solid #ddd;
            color: #333;
        }
        .select:hover {
            border: 1px solid #b9b9b9;
        }
        .select:focus {
            border: 1px solid #b9b9b9;
        }
    }

    &.dark {
        .select {
            border: 1px solid #575757;
            color: #ddd;
        }

        .select:hover {
            border: 1px solid #828282;
        }
        .select:focus {
            border: 1px solid #828282;
            outline: none;
        }
    }

    /* class applies to select element itself, not a wrapper element */
    .select {
        display: inline-block;
        font-size: 16px;
        font-family: sans-serif;
        //font-weight: 700;
        line-height: 18px;
        padding: ($baseline / 2) ($baseline * 2) ($baseline / 2) $baseline;
        max-width: 100%;
        box-sizing: border-box;
        border-radius: 3px;
        transition: border 0.1s ease-in;
        margin: 0;
        box-shadow: $shadow-standard;
        border-radius: 3px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
        /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
		for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
		
	*/
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
            linear-gradient(to bottom, transparent 0%, transparent 100%);
        background-repeat: no-repeat, repeat;
        /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
        background-position: right 0.7em top 50%, 0 0;
        /* icon size, then gradient */
        background-size: 0.65em auto, 100%;
    }
    /* Hide arrow icon in IE browsers */
    .select::-ms-expand {
        display: none;
    }
    /* Hover style */
    .select:hover {
    }
    /* Focus style */
    .select:focus {
        outline: none;
    }

    /* Set options to normal weight */
    .select option {
        font-weight: normal;
    }

    /* Disabled styles */
    .select:disabled,
    .select[aria-disabled="true"] {
        color: graytext;
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
            linear-gradient(to bottom, #eaeaea 0%, #eaeaea 100%);
    }

    .select:disabled:hover,
    .select[aria-disabled="true"] {
        border-color: #aaa;
    }
}
