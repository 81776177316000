@import "../../style/variables.scss";

$margin-space: 160px; // Mainly for giving space for graphic
%themeBase {
    position: sticky;
    left: 0;
    width: 100%;
    z-index: $z-menu;
    top: 0;

    &.toggled:not(.navigationFits) {
        z-index: $z-menu-expanded;
    }

    .hideUntilMesurmentIsDone {
        display: none;
    }

    &.editAvailable {
        top: $action-bar-height;
    }

    .toggleButton {
        letter-spacing: 2px;
        position: relative;
        z-index: 1;
        display: flex;
        padding: 0 $baseline;
        align-items: center;
        height: $menu-height;
        text-transform: uppercase;
        font-size: 12px;
        cursor: pointer;

        svg {
            height: 20px;
            margin-right: $baseline / 2;
        }
    }

    &.navigationFits .toggleButton {
        display: none;
    }

    .menu {
        width: 100%;

        &.measurementOnly {
            visibility: hidden;
            position: absolute;
            z-index: -100;
            top: -99999px;
            overflow: hidden;
            max-width: 100%;
        }

        .editMenuButtonWrapper {
            display: flex;
            justify-content: center;
            margin-bottom: $baseline;
        }

        &.navigationFits .editMenuButtonWrapper {
            margin-bottom: 0;
            width: $margin-space;
            justify-content: flex-end;
        }

        .rightSpacer {
            display: none;
        }

        &.navigationFits .rightSpacer {
            display: block;
            width: $margin-space;
        }

        &:not(.navigationFits) {
            transition: transform 0.1s ease-out, top 0s 0.1s ease;
            top: -9999px;
            position: absolute;
            transform: translateY(-100%);
        }

        &.navigationFits {
            display: flex;
            justify-content: center;
            .innerWrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                height: $menu-height-large;
            }
        }

        ul {
            padding: 0 ($baseline / 2);
            margin: 0;
            list-style-type: none;
        }

        &.navigationFits ul {
            display: flex;
        }
    }

    .menu.toggled:not(.navigationFits) {
        transition: transform 0.2s ease-out, top 0s 0s;
        transform: translateY(0%);
        top: $menu-height;
    }
}

%themeBase {
    box-shadow: var(--generator-menu-shadow);
    .toggleButton {
        background-color: var(--generator-menu-background);
        color: var(--generator-menu-text-color);
        font-size: 14px;
        font-weight: var(--generator-menu-font-weight);
    }

    .menu {
        background-color: var(--generator-menu-background);

        &.toggled {
            box-shadow: var(--generator-menu-shadow);
        }
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme5, .theme11,
.theme9,
.theme6 {
    @extend %themeBase;
}

.theme7 {
    .toggleButton,
    .menu {
        background: rgba(255, 255, 255, 0.6);
        box-shadow: $shadow-standard;
        backdrop-filter: blur(5px);
        border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    }

    .toggleButton {
        &.toggled {
            border-bottom: 0;
            box-shadow: unset;
        }
    }
}

.theme9 {
    .toggleButton,
    .menu {
        background: hsla(var(--color-surface-h), var(--color-surface-s), calc(var(--color-surface-l)), 0.8);
        box-shadow: $shadow-standard;
        backdrop-filter: blur(5px);
    }

    .toggleButton {
        &.toggled {
            border-bottom: 0;
            box-shadow: unset;
        }
    }
}
