@import "../../../../style/variables.scss";

.outerWrapper {
    &.THUMBNAIL {
        width: 100%;
        height: 100%;
        position: relative;

        .innerWrapper {
            width: 100%;
            height: 100%;
        }
    }

    &.SITE {
        z-index: -1;
    }

    &.PRINT_PAGE_1, &.PRINT_PAGE_2 {
        display: none;
    }

    margin: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    position: fixed;

    .innerWrapper {
        width: 100vw;
        height: 100vh;
        background: var(--color-background);
    }

    $particleSize: 20vmin;
    $animationDuration: 6s;
    $amount: 20;

    .innerWrapper span {
        width: $particleSize;
        height: $particleSize;
        border-radius: $particleSize;
        backface-visibility: hidden;
        position: absolute;
        animation-name: move;
        animation-duration: $animationDuration;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        $colors: (
            hsla(calc(var(--color-background-h) + 0), calc(var(--color-background-s) + 10%), 50%, 25%),
            hsla(calc(var(--color-background-h) + 0), calc(var(--color-background-s) - 10%), 50%, 25%)
        );
        @for $i from 1 through $amount {
            &:nth-child(#{$i}) {
                color: nth($colors, random(length($colors)));
                top: random(100) * 1%;
                left: random(100) * 1%;
                animation-duration: (random($animationDuration * 10) / 10) * 1s + 10s;
                animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
                transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
                $blurRadius: (random() + 0.5) * $particleSize * 0.5;
                $x: if(random() > 0.5, -1, 1);
                box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
            }
        }
    }

    @keyframes move {
        100% {
            transform: translate3d(0, 0, 1px) rotate(360deg);
        }
    }
}
