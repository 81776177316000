@use "sass:math";
@import "../../../../style/variables.scss";

.wrapper {
    position: relative;

    .editable {
        padding: ($baseline / 2);
        margin: -(($baseline / 2));
    }

    .editable.focused {
        border: 1px dashed rgba(0, 0, 0, 0.3);
        border-top: 0;
        padding: ($baseline / 2 - 1);
        padding-top: $baseline / 2;
    }
}
