@import "../../../../../../../../../../style/variables.scss";

.sameAsSiteWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: $baseline * 2;
}

.roundedCornersWrapper {
    margin-bottom: $baseline * 2;
    display: flex;
    justify-content: center;
}