@import "../../../../../../../../style/variables.scss";

.wrapper {
    background: #fff;
    padding: $baseline;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
    width: 100%;

    .sectionTitle {
        font-family: $font-standard;
        margin: 0 0 ($baseline) 0;
        letter-spacing: 0;
        text-align: center;
    }

    @media (min-width: $breakpoint-m) {
        padding: $baseline * 1.5;
    }

    .cardWrappers {
        gap: $baseline * 2;
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media (min-width: $breakpoint-m) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        .exampleImage {
            display: block;
            line-height: 0;
            box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
        }
    }
}
