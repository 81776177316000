@import "../../../../style/variables.scss";

.wrapper {
    max-width: 500px;
    margin: ($baseline * 2) auto;
    padding: 0 $baseline;

    .section {
        text-align: left;
        background: #fff;
        margin: 0 auto;
        padding: $baseline;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
        display: flex;
        flex-direction: column;
        gap: $baseline;
        margin-top: $baseline * 2;

        h3 {
            margin-top: 0;
        }

        @media (min-width: $breakpoint-m) {
            padding: $baseline * 1.5;
        }
    }

}
