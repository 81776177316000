@import "../../../../../../style/variables.scss";

.wrapper {
    cursor: pointer;
    //background: #252526;
    //padding: $baseline / 2;
    border-radius: 4px;
    line-height: 0;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;

    .text {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;   
    }

    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: scale(1.05);
    }
    
}
