@import "../../../../style/variables.scss";

.wrapper {
    background: #2d2d2d;
    top: 0;
    left: 0;
    width: 100%;
    height: $generator-edit-menu-height;
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 0;
    z-index: $z-action-bar;
    position: relative;

    & > div {
        display: flex;
    }

    button,
    a {
        text-decoration: none;
        background: transparent;
        border: 0;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        color: #cccccc;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        transition: color 0.3s ease-out;

        padding: 0 ($baseline);

        font-weight: bold;
        font-size: 13px;
        line-height: 13px;

        .icon {
            width: $baseline;
            height: $baseline;
            margin-right: $baseline / 2;
            svg {
                height: $baseline * 1;
            }
        }
        outline: none !important;

        &.active {
            color: #b69860;
        }

        &:hover {
            color: #ffb607;
            transition: none;
        }
    }

    @media (max-width: 360px) {
        .menuItems {
            button,
            a {
                padding: 0 ($baseline / 2);
            }
        }
    }

    @media (max-width: 450px) {
        .menuItems {
            .icon {
                display: none;
            }
        }
    }

    @media (max-width: 600px) {
        .additionalOptions {
            .text {
                display: none;
            }
            .icon {
                margin-right: 0;
            }
        }
    }

    @media (max-width: 800px) {
        .additionalOptions {
            .feedback {
                display: none;
            }
        }
    }
}
