@import "../../../style/variables.scss";

%themeBase {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: ($baseline / 2) 0 ($baseline / 2) 0;

    @media (min-width: $breakpoint-m) {
        margin: 0;
        flex-wrap: nowrap;
        width: 100%;
    }

    color: var(--generator-label-color);
    font-family: var(--generator-label-font-family);
    font-size: var(--generator-label-font-size);

    .label {
        width: 100%;
        @media (min-width: $breakpoint-m) {
            padding: ($baseline / 2) ($baseline / 2) ($baseline / 2) 0;
            width: 50%;
        }

        &:empty:before {
            content: attr(placeholder);
            color: rgba(0, 0, 0, 0.4);
        }
    }

    .options {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @media (min-width: $breakpoint-m) {
            flex-wrap: nowrap;
            padding-left: $baseline / 2;
            width: 50%;
        }
        .optionYes {
            margin-right: $baseline;
        }
    }
}

%themeBase {
    .label {
        @media (min-width: $breakpoint-m) {
            padding-right: var(--generator-spacing-050);
        }
    }

    .options {
        @media (min-width: $breakpoint-m) {
            padding-left: var(--generator-spacing-050);
        }
    }
}

.yesOrNoWrapper {
    position: relative;
    .editBarWrapper {
        position: absolute;
        top: 100%;
        left: $baseline;
        z-index: 1;
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5, .theme11,
.theme6 {
    @extend %themeBase;
}
