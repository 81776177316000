@import "../../../../../../../../../../../../style/variables.scss";

.wrapper {
    text-align: center;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    line-height: 1.6;
    position: relative; // Needed for graphics one level up

    font-family: var(--font-text);
    color: var(--color-on-background);

    font-size: 2.8cqw;
    letter-spacing: 0.1cqw;

    &.page1 {
        background: var(--color-primary);
        color: var(--color-on-primary);
        display: flex;
        flex-direction: column;
        .imageWrapper {
            width: 100%;
            aspect-ratio: 1.1;
            max-width: 100%;
            overflow: hidden;

            .primaryImage {
                line-height: 0;
            }
        }

        .boxWrapper {
            flex: 1;
            padding: 4cqw;
            display: flex;

            .innerBoxWrapper {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-bottom: 3cqw;

                .title {
                    font-size: 9cqw;
                    line-height: 1.2;
                    font-family: var(--font-heading);
                }

                .names {
                    margin-top: 1cqw;
                    padding: 0 6cqw;
                    font-size: 3cqw;
                }
                .date {
                    font-size: 3cqw;
                }
            }
        }
    }

    &.page2 {
        align-items: center;
        .qr {
            width: 30cqw;
            line-height: 0;
            margin: 0 auto;

            .img {
                border: 1cqw solid white;
                border-radius: 2px;
            }
        }

        .textAtQR {
            margin-bottom: 3cqw;
            padding: 0 6cqw;
        }
    }
}
