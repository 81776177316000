@import "../../../../style/variables.scss";

.wrapper {
    width: 100%;
    margin: ($baseline) 0;
    background: #252526;
    border-radius: 4px;
}
.text {
    padding: $baseline;
    margin: 0;
}

.versionsList {
    margin: 0;
    padding: 0 0;
    list-style-type: none;

    & > li {
        border-top: 1px solid #3b3b3b;
        button {
            cursor: pointer;
            padding: ($baseline / 2) $baseline;
            width: 100%;
            text-align: left;
            font-size: 16px;

            background: transparent;
            border: 0;
            color: $color-edit-action-text;

            outline: none !important;

            &:hover {
                color: $color-edit-action-text-hover;
                background: $color-edit-action-hover;
            }
        }

        &.selected {
            button {
                cursor: auto;
                font-weight: bold;
            }
        }
    }
}
