@import "../../../style/variables.scss";

.textFieldWrapper {
    label,
    :global(.actAsLabel) {
        font-size: 14px;
        margin-left: 2px;
        display: block;
    }

    input {
        border-radius: 3px;
        border: 1px solid #c0c0c0;

        &:valid ~ [data-invalid-feedback] {
            display: none;
        }
    }

    :global(.invalidFeedback:not(:empty)) {
        color: $color-form-error;
        margin-top: 3px;
        font-size: 12px;
    }
}

.inputClickArea {
    cursor: pointer;
    &:focus {
        outline: $editable-outline-focus;
    }

    &:hover {
        outline: $editable-outline-hover;
    }
}

.editBarWrapper {
    position: absolute;
    bottom: 0;
    justify-content: center;
    width: 100%;
    display: flex;
    & > * {
        margin: (0) ($baseline / 2) ($baseline / 2) ($baseline / 2);
    }
}
