@import "../../style/variables.scss";

%themeBase {
    -webkit-appearance: none;
    position: relative;
    z-index: 0;

    justify-content: center;
    display: flex;

    .innerWrapper,
    .innerWrapperWithClick {
        justify-content: center;
        display: flex;
        gap: 8px;
    }

    .innerWrapperWithClick {
        cursor: pointer;
        &:focus {
            outline: $editable-outline-focus;
        }

        &:hover {
            outline: $editable-outline-hover;
        }
    }

    button {
        border: 0;
        border-radius: var(--generator-button-radius);
        font-family: var(--font-text);
        font-weight: var(--generator-button-font-weight);
        letter-spacing: var(--generator-button-letter-spacing);
        font-size: var(--generator-button-font-size);
        background: var(--generator-button-background);
        color: var(--generator-button-text-color);
        display: flex;
        justify-content: center;
        align-items: center;
        width: $baseline * 2.5;
        height: $baseline * 2.5;
        font-weight: bold;
        transition: opacity 0.2s ease, transform 0.2s ease;

        &:disabled {
            color: var(--generator-button-text-color);
            cursor: initial;
        }

        &:not(.nonHover) {
            cursor: pointer;
            &:hover {
                transform: scale(1.2);
                outline: 0;
            }

            &.selectedExists {
                opacity: 0.7;
                transform: scale(0.9);

                &.selected {
                    opacity: 1;
                    transform: scale(1.1);
                }
            }
        }
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5, .theme11,
.theme6 {
    @extend %themeBase;
}
