@import "../../style/variables.scss";

%themeBase {
    .textFieldWrapper {
        position: relative;

        .addNewTextFieldInactive {
            filter: blur(2px);
            opacity: 0.8;
        }

        .addNewTextFieldButton {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            margin: 0 auto ($baseline) auto;
        }
    }
}

%themeBase {
    display: grid;
    gap: var(--generator-spacing-100);

    .textFieldWrapper {
        width: 100%;
    }
}

%themeBaseResponsive {
    @media (min-width: $breakpoint-m) {
        grid-template-columns: 1fr 1fr;
    }
}

.theme5, .theme11,
.theme6 {
    @extend %themeBase;
}

.theme4,
.theme10,
.theme8,
.theme9,
.theme7 {
    @extend %themeBase;
    @extend %themeBaseResponsive;
}
