@import "../../../../style/variables.scss";

.wrapper {
    text-align: center;

    h1 {
        margin-bottom: 0;
    }

    .stepCounter {
        font-size: 14px;
        font-weight: normal;
        display: block;
        margin-bottom: $baseline * 2;
    }

    .intro {
        font-size: 18px;
    }
}
