@import "../../../../../../../../style/variables.scss";

.wrapper {
    .editWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: ($baseline * 2) $baseline;
        gap: $baseline;
    }

}

.buttonWrapper {
    display: flex;
    margin-top: $baseline;
    gap: $baseline;
}