@import "../../style/variables.scss";

.wrapper {
    text-decoration: none;
    display: inline-block;
    border-radius: 1px;
    background: transparent;
    -webkit-appearance: none;
    border-radius: 3px;
    position: relative;
    z-index: 0;

    button {
        margin: 0;
        background: transparent;
        padding: $baseline / 2;
        line-height: 12px;
        font-size: 12px;
        text-align: center;
        border: 0;
        box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 0;

        background: $color-edit-action;
        color: $color-edit-action-text;
        font-weight: bold;

        transition: opacity 0.2s ease;
        cursor: pointer;

        &:hover,
        &:focus {
            outline: 0;
            color: $color-edit-action-text-hover;
        }

        opacity: 0.8;

        &.selected {
            opacity: 1;
        }

        &:last-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        &:first-child {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }
    }
}
