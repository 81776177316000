@import "../../../../style/variables.scss";

.wrapper {
    max-width: $content-max-width;
    margin: ($baseline * 2) auto;
    padding: 0 $baseline;

    .sectionTitle {
        font-family: $font-standard;
        margin: 0 0 ($baseline / 2) 0;
        letter-spacing: 0;
    }

    .section {
        background: #fff;
        padding: $baseline;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);

        @media (min-width: $breakpoint-m) {
            padding: $baseline * 1.5;
        }
    }

    .grid {
        display: grid;
        column-gap: $baseline * 2;
        row-gap: $baseline * 2;

        @media (min-width: $breakpoint-m) {
            grid-template-columns: 1fr 1fr;
        }
    }

    .accountButtonsWrapper { 
        display: flex;
        gap: $baseline;
        margin-top: $baseline;
    
        .resetPassword {
            line-height: normal;
        }
    }


    .descriptionText {
        margin-top: $baseline / 2;
        font-size: 14px;
    }

    .visitorCountWrapper {
        font-size: $baseline * 1.5;
        line-height: $baseline * 1.5;
        display: inline-block;
        margin: $baseline 0;
        display: flex;
        align-items: center;

        .count {
            border: 1px solid rgba(0, 0, 0, 0.2);
            padding: $baseline / 2;
            border-radius: 4px;
            margin-right: $baseline / 2;
        }

        .text {
            color: rgba(0, 0, 0, 0.6);
        }
    }
}
