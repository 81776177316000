@import "../../../../../../style/variables.scss";

.wrapper {
    max-width: 1600px;
    margin: $baseline auto;
    padding: 0 $baseline;

    .intro {
        font-size: 18px;
        margin-bottom: $baseline * 2;
    }

    .totalCount {
        font-size: 14px;
        font-weight: normal;
        display: block;
    }


    .table {
        width: 100%;
        border-collapse: collapse;
    }

    th {
        text-align: left;
    }

    td,
    tr,
    th {
        padding: $baseline / 2;
        padding: 20px ($baseline / 2);
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    }
}
