@import "../../style/variables.scss";

.outerWrapper {
    display: flex;
    justify-content: center;
    margin-top: $baseline;
    margin-bottom: $baseline;
    .wrapper {
        background: #252526;
        border-radius: 2px;
        font-family: $font-standard;
        color: #cccccc;
        padding: ($baseline / 2) $baseline;

        .languageTitle {
            font-size: 12px;
            text-transform: uppercase;
        }
        position: relative;

        &.noTranslation {
            opacity: 0.7;
        }

        // Arrow
        &:after {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(213, 124, 204, 0);
            border-bottom-color: #252526;
            border-width: $baseline / 2;
            margin-left: -$baseline / 2;
        }
    }
}
