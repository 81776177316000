@import "../../style/variables.scss";

.wrapper,
.wrapperExpanded {
    background: #252526;
    border-radius: 2px;
    margin-bottom: $baseline * 2;

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: background 100ms ease-in-out;

        &:hover {
            background: rgba(0, 0, 0, 0.1);
        }

        .title {
            font-weight: bold;
            padding: $baseline;
        }

        .icon {
            transition: transform 200ms ease-in-out;
            transform: rotate(0deg);
            width: $baseline;
            margin: 0 $baseline;
            line-height: 0;
        }
    }

    .content {
        display: none;
        padding: $baseline;
    }
}

.wrapperExpanded {
    .top {
        .icon {
            transform: rotate(180deg);
        }
    }

    .content {
        display: block;
    }
}
