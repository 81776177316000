@import "../../../../style/variables.scss";

.editWarning {
    padding: $baseline;
    background: #252526;
    border-radius: 4px;
    strong {
        color: #e9796b;
    }
    margin-bottom: $baseline;
}

.confirmationWrapper {
    display: flex;
    gap: $baseline;
    flex-direction: column;

    @media (min-width: $breakpoint-l) {
        & > * {
            flex: 1;
        }
        flex-direction: row;
    }
 }

 .othersWrapper {
    display: flex;
    gap: $baseline;
    flex-direction: column;

    @media (min-width: $breakpoint-l) {
        & > * {
            flex: 1;
        }
        flex-direction: row;
    }

    .sendButtonAndTitle {
        display: flex;
        gap: $baseline;
        flex-direction: column;
    }
 }


