@import "../../../../../../../../style/variables.scss";

.questionValueWrapper {
    display: block;

    .label {
        font-size: 12px;
        font-weight: bold;
        color: #444;
        text-transform: uppercase;
        margin-bottom: $baseline / 4;
    }
}
