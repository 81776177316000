@import "../../style/variables.scss";

%themeBase {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: var(--generator-spacing-200);
    padding-bottom: var(--generator-module-spacing-bottom);
    padding-top: var(--generator-module-spacing-bottom);

    .imageWrapper {
        border: var(--generator-header-image-border);
        border-radius: var(--generator-header-image-wrapper-border-radius);
        margin: 0 auto;
        padding: var(--generator-header-image-wrapper-padding);

        img,
        :global(.image-placeholder) {
            border-radius: var(--generator-header-image-border-radius);
            width: var(--internal-header-image-max-width);
            max-width: 100%;
        }

        display: inline-block;
        line-height: 0;
    }

    .imageSizeConstrainer {
        &.LARGE {
            --internal-header-image-max-width: var(--generator-header-image-max-width-large);
        }

        &.MEDIUM {
            --internal-header-image-max-width: var(--generator-header-image-max-width-medium);
        }

        &.SMALL {
            --internal-header-image-max-width: var(--generator-header-image-max-width-small);
        }
    }

    .textWrapper {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        order: var(--generator-header-text-order);

        .textInnerWrapper {
            .yetAnotherInnerWrapper {
                h1 {
                    margin: 0;
                    padding: 0;
                    font-size: var(--generator-header-heading-1-size);
                    letter-spacing: var(--generator-header-heading-1-letter-spacing);
                    font-weight: var(--generator-header-heading-1-font-weight);
                    color: var(--generator-header-heading-color);
                    line-height: 1.1;
                    margin-bottom: var(--generator-header-heading-1-margin-bottom);
                }

                .text {
                    letter-spacing: var(--generator-letter-spacing-wider);
                    color: var(--generator-header-heading-2-color);
                    font-family: var(--generator-header-heading-2-font);
                    font-size: var(--generator-header-heading-2-font-size);
                    opacity: var(--generator-header-heading-2-opacity);
                    font-weight: var(--generator-header-heading-2-font-weight);
                }
            }
        }
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5,
.theme11,
.theme6 {
    @extend %themeBase;
}

.theme5,
.theme11 {
    padding-top: 0;
}

.theme6 {
    position: fixed;
    left: 0;
    top: $menu-height;
    width: calc(100vw - var(--generator-page-content-max-width));
    gap: unset;
    display: block;

    .imageWrapper {
        height: calc(100vh - #{$menu-height});
        width: calc(100vw - var(--generator-page-content-max-width));
        img {
            width: 100%;
            height: calc(100vh - #{$menu-height});
            object-fit: cover;
            max-width: unset;
        }
    }

    .textWrapper {
        position: absolute;
        text-align: left;
        //color: #e0ab65;
        left: 2rem;
        bottom: 2rem;

        .yetAnotherInnerWrapper {
            display: flex;
            flex-direction: column;
        }
        h1 {
            order: 1;
        }

        .textInnerWrapper {
            z-index: 1; // Does not working in IE11 otherwise
        }
    }
}

.theme7 {
    gap: 0;
    padding-top: 0;
    @media (min-width: $breakpoint-s) {
        margin-bottom: 3rem;
        min-height: 260px; // only if no image, this could be much better done...
    }
    @media (min-width: $breakpoint-l) {
        padding-top: calc(var(--generator-module-spacing-bottom) / 2);
    }

    .textWrapper {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -3rem;

        @media (min-width: $breakpoint-s) {
            margin-top: 0;
            position: absolute;
            bottom: 3rem;
        }

        h1 {
            --gradient-to-color: hsla(
                var(--color-on-primary-h),
                var(--color-on-primary-s),
                calc(var(--color-on-primary-l) - 7%),
                var(--color-on-primary-a)
            );
            --gradient-from-color: hsla(
                var(--color-on-primary-h),
                var(--color-on-primary-s),
                calc(var(--color-on-primary-l) + 7%),
                var(--color-on-primary-a)
            );
            background: linear-gradient(45deg, var(--gradient-from-color) 0%, var(--gradient-to-color) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .textInnerWrapper {
            z-index: 1;
            .yetAnotherInnerWrapper {
                position: relative;
                &:before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;

                    background: var(--color-primary);
                    border-radius: 2px;
                    box-shadow: $shadow-standard;
                }
                border-radius: 5px;
                padding: ($baseline * 2) $baseline * 3;
            }
        }
    }

    .imageWrapper {
        @media (min-width: $breakpoint-s) {
            :global(.image-placeholder) {
                min-height: 400px;
            }
        }
    }
}

.theme8 {
    --gradient-to-color: hsla(
        calc(var(--color-primary-h) + 40),
        var(--color-primary-s),
        var(--color-primary-l),
        var(--color-primary-a)
    );
    --gradient-from-color: hsla(
        calc(var(--color-primary-h) - 40),
        var(--color-primary-s),
        var(--color-primary-l),
        var(--color-primary-a)
    );
    background: linear-gradient(45deg, var(--gradient-from-color) 0%, var(--gradient-to-color) 100%);
    background-size: cover;

    display: flex;
    @media (min-width: $breakpoint-m) {
        flex-direction: row;
        gap: var(--generator-spacing-300);
    }

    padding: var(--generator-spacing-400);

    .imageWrapper {
        @media (min-width: $breakpoint-m) {
            max-width: 30vw;
        }
        margin: 0;
        position: relative;

        &::before {
            border: 4px solid var(--color-on-primary);
            position: absolute;
            content: "";
            max-width: var(--internal-header-image-max-width);
            width: calc(100% + 20px);
            aspect-ratio: 1 / 1;
            top: -10px;
            left: -10px;
            border-radius: 50%;
        }

        img,
        :global(.image-placeholder) {
            position: relative;
            object-fit: cover;
            aspect-ratio: 1 / 1;
            border-radius: 50%;
        }
    }
}

.theme9 {
    position: relative;
    padding-top: 0;
    padding-bottom: 0;

    &.verticalAlignmentBottom {
        justify-content: flex-end;
    }
    &.verticalAlignmentTop {
        justify-content: flex-start;
    }

    .textWrapper {
        position: absolute;
        padding: var(--generator-spacing-200);

        .textInnerWrapper {
            .yetAnotherInnerWrapper {
                h1,
                .text {
                    color: #fff;
                }

                .text {
                    letter-spacing: -0.025rem;
                }
            }
        }
    }

    .imageWrapper {
        width: 100%;
        margin: 0;
        text-align: center;

        img,
        :global(.image-placeholder) {
            filter: brightness(0.5);
            position: relative;
            max-height: 80vh;
            object-fit: cover;
        }
    }
}

.theme10 {
    .textWrapper {
        .textInnerWrapper {
            .yetAnotherInnerWrapper {
                h1 {
                    color: var(--color-on-background);
                    padding: 0 var(--generator-spacing-100);
                }
                .text {
                    display: inline-block;
                    position: relative;
                    background: var(--color-primary);
                    color: var(--generator-heading-color);
                    letter-spacing: 2px;
                    padding: var(--generator-spacing-025) var(--generator-spacing-200);
                    clip-path: polygon(
                        100% 0%,
                        calc(100% - var(--generator-spacing-050)) 50%,
                        100% 100%,
                        0 100%,
                        var(--generator-spacing-050) 50%,
                        0 0
                    );
                    margin: 0 var(--generator-spacing-100);

                    @media (max-width: $breakpoint-s) {
                        font-size: 14px;
                    }

                    &:global(.font-Cutive_Mono) {
                        &::before {
                            text-align: center;
                            white-space: nowrap;
                            overflow: hidden;
                            background: var(--color-primary);
                            position: absolute;
                            right: 0;
                            top: 0;
                            height: 100%;
                            margin: var(--generator-spacing-025) var(--generator-spacing-200);
                            content: "";

                            animation: typewriterInverted 4s steps(var(--number-of-chars-in-secondline)) 1s 1 normal
                                both;

                            @keyframes typewriterInverted {
                                from {
                                    width: calc(100% - var(--generator-spacing-400));
                                }
                                to {
                                    width: 0%;
                                }
                            }
                        }

                        &::after {
                            border-right: 2px solid red;
                            text-align: center;
                            white-space: nowrap;
                            overflow: hidden;
                            background: transparent;
                            position: absolute;
                            opacity: 0;
                            left: 0;
                            top: 0;
                            height: calc(100% - var(--generator-spacing-100));
                            content: "";
                            margin: var(--generator-spacing-050) var(--generator-spacing-200);

                            animation: typewriter 4s steps(var(--number-of-chars-in-secondline)) 1s 1 normal both,
                                blinkTextCursor 500ms steps(var(--number-of-chars-in-secondline)) infinite normal,
                                hideWhenFinished 10s;

                            @keyframes typewriter {
                                from {
                                    width: 0%;
                                }
                                to {
                                    width: calc(100% - var(--generator-spacing-400));
                                }
                            }

                            @keyframes hideWhenFinished {
                                0% {
                                    opacity: 1;
                                }
                                99% {
                                    opacity: 1;
                                }
                                100% {
                                    opacity: 0;
                                }
                            }
                        }

                        @keyframes blinkTextCursor {
                            from {
                                border-right-color: rgba(255, 255, 255, 0.75);
                            }
                            to {
                                border-right-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
}

.theme11 {
    gap: 0;
    .textWrapper {
        width: 100%;
        background: var(--color-primary);
        padding: var(--generator-spacing-100);
        .textInnerWrapper {
            width: 100%;
            border: 1px solid var(--color-on-primary);
            padding: var(--generator-spacing-200);
            border-radius: 0px;
            .yetAnotherInnerWrapper {

                h1 {
                    color: var(--color-on-primary);
                }
            }
        }
    }
}
