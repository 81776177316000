@import "../../style/variables.scss";

.wrapper {
    color: $color-edit-action-text;
    display: flex;
    position: absolute;
    flex-direction: column;
    top: $baseline / 2;
    right: $baseline / 2;
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 0;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;

    .toggle {
        display: flex;
        padding: $baseline / 2;
        height: $baseline * 2;
        svg {
            margin-right: 5px;
        }
        z-index: $z-content-module-action-bar;
        position: relative;
        background: $color-edit-action;
    }

    .actions {
        display: none;
        flex-direction: column;

        &.open {
            display: flex;
        }
        z-index: $z-content-module-action-bar + 1;
        position: relative;
        background: $color-edit-action;
    }

    button {
        background: transparent;
        white-space: nowrap;
        border: 0;
        color: $color-edit-action-text;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: ($baseline) ($baseline / 2);
        font-weight: bold;
        cursor: pointer;
        height: $baseline * 4;

        @media (min-width: 400px) {
            padding: $baseline;
        }

        outline: none !important;

        & > svg {
            height: $baseline * 1;
        }

        span {
            display: block;
        }

        &:hover {
            color: $color-edit-action-text-hover;
            background: $color-edit-action-hover;
        }
    }
}
