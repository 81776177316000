@import "../../../../../../style/variables.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    gap: $baseline;
    position: relative;

    .row {
        gap: $baseline;
        display: flex;
        flex-direction: column;
    }
    
    @media (min-width: $breakpoint-m) {
        .row {
            flex-direction: row;
            gap: $baseline * 2;
            & > * {
                flex: 1;
            }
        }
    }

    &.disabled:after {
        content: "";
        background: rgba(255, 255, 255, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.changeButton {
    margin-top: $baseline;
}
