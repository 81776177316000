@import "../../../../../../style/variables.scss";

.wrapper {
    max-width: $content-max-width;
    margin: ($baseline * 2) auto;
    padding: 0 $baseline;

    h1 {
        text-align: center;
    }

    .availableTitle,
    .notAvailableTitle {
        color: #388e3c;
        font-size: 20px;
        margin-top: 0;
    }

    .notAvailableTitle {
        color: $color-form-error;
    }

    .infoBox {
        margin-top: $baseline * 2;
        background: #fff;
        padding: $baseline * 2;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
    }

    .hostWrapper {
        background: #fff;
        padding: $baseline * 2;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
        display: flex;
        gap: $baseline;
        flex-direction: column;

        .inputAndButtonWrapper {
            display: flex;
            gap: $baseline;
            flex-direction: column;

            @media (min-width: $breakpoint-m) {
                flex-direction: row;
                align-items: end;
            }

            & > * {
                flex: 1;
            }

            label {
                font-size: 14px;
                margin-left: 2px;
                display: block;
            }
            .inputWrapper {
                position: relative;
                border-radius: 3px;
                border: 1px solid #c0c0c0;
                display: flex;
                align-items: center;
                .host {
                    padding: 0 ($baseline / 2);
                    font-size: 12px;
                    border-left: 1px solid #c0c0c0;

                    @media (min-width: $breakpoint-s) {
                        padding: 0 $baseline;
                        font-size: 16px;
                    }
                }

                input {
                    border: 0;
                }
            }
        }
    }

    .buttonWrapper {
        margin-top: $baseline;
    }
}
