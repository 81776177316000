@import "../../style/variables.scss";

.wrapper {
    display: grid;
    column-gap: $baseline;
    align-items: stretch;
    justify-items: stretch;

    &.cols2 {
        grid-template-columns: 1fr;
        text-align: center;

        @media (min-width: $breakpoint-s) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &.cols3 {
        grid-template-columns: 1fr 1fr 1fr;
        text-align: center;
    }
}
