@import "../../style/variables.scss";

.default {
    --generator-header-heading-2-color: var(--color-on-background);
    --generator-header-heading-color: var(--generator-heading-color);
    --generator-header-image-max-width-large: 1200px;
    --generator-header-image-max-width-medium: 968px;
    --generator-header-image-max-width-small: 400px;
    --generator-heading-3-font-family: var(--font-heading);
}

.variables-rem {
    --generator-spacing-100: 1.6rem;
    --generator-spacing-025: calc(var(--generator-spacing-100) * 0.25);
    --generator-spacing-050: calc(var(--generator-spacing-100) * 0.5);
    --generator-spacing-075: calc(var(--generator-spacing-100) * 0.75);
    --generator-spacing-150: calc(var(--generator-spacing-100) * 1.5);
    --generator-spacing-200: calc(var(--generator-spacing-100) * 2);
    --generator-spacing-300: calc(var(--generator-spacing-100) * 3);
    --generator-spacing-400: calc(var(--generator-spacing-100) * 4);

    --generator-spacing-negative-100: calc(#{var(--generator-spacing-100)} * -1);
    --generator-spacing-negative-200: calc(#{var(--generator-spacing-200)} * -1);

    --generator-border-radius-md: var(--generator-spacing-100);
    --generator-border-radius-sm: calc(var(--generator-border-radius-md) * 0.5);
    --generator-border-radius-lg: calc(var(--generator-border-radius-md) * 2);

    --generator-letter-spacing-wide: 0.025em;
    --generator-letter-spacing-wider: 0.05em;
    --generator-letter-spacing-widest: 0.1em;
}

.variables-px {
    --generator-spacing-100: 16px;
    --generator-spacing-025: calc(var(--generator-spacing-100) * 0.25);
    --generator-spacing-050: calc(var(--generator-spacing-100) * 0.5);
    --generator-spacing-075: calc(var(--generator-spacing-100) * 0.75);
    --generator-spacing-150: calc(var(--generator-spacing-100) * 1.5);
    --generator-spacing-200: calc(var(--generator-spacing-100) * 2);
    --generator-spacing-300: calc(var(--generator-spacing-100) * 3);
    --generator-spacing-400: calc(var(--generator-spacing-100) * 4);

    --generator-spacing-negative-100: calc(#{var(--generator-spacing-100)} * -1);
    --generator-spacing-negative-200: calc(#{var(--generator-spacing-200)} * -1);

    --generator-border-radius-md: var(--generator-spacing-100);
    --generator-border-radius-sm: calc(var(--generator-border-radius-md) * 0.5);
    --generator-border-radius-lg: calc(var(--generator-border-radius-md) * 2);

    --generator-letter-spacing-wide: 0.025em;
    --generator-letter-spacing-wider: 0.05em;
    --generator-letter-spacing-widest: 0.1em;
}

.theme4 {
    @extend .variables-rem;
    @extend .default;

    --generator-module-max-width: 1000px;
    --generator-module-spacing: 0 16px;
    --generator-module-spacing-bottom: var(--generator-spacing-300);
    --generator-module-heading-padding: var(--generator-spacing-200) 0 var(--generator-spacing-100) 0;

    --generator-page-content-max-width: unset;
    --generator-page-wrapper-background: transparent;
    --generator-page-wrapper-padding: 0;
    --generator-page-wrapper-border-radius: unset;
    --generator-page-margin-top: 0;

    --generator-content-box-background: var(--color-surface);
    --generator-content-box-padding: var(--generator-spacing-200);
    --generator-content-box-padding-large: var(--generator-spacing-300);
    --generator-content-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
    --generator-content-box-text-color: var(--color-on-surface);

    --generator-image-radius: 0;

    --generator-textfields-radius: 0;

    --generator-label-text-align: left;
    --generator-label-color: var(--color-on-surface);
    --generator-label-font-size: 14px;
    --generator-label-margin-bottom: 4px;

    --generator-input-padding: 8px;
    --generator-input-border: 0px;
    --generator-input-border-bottom: 2px solid var(--color-primary);
    --generator-input-background: rgba(0, 0, 0, 0.03);
    --generator-input-text-color: var(--color-on-surface);

    --generator-button-radius: 2px;
    --generator-button-width: auto;
    --generator-button-padding: 12px 48px;
    --generator-button-font-weight: unset;
    --generator-button-letter-spacing: var(--generator-letter-spacing-widest);
    --generator-button-text-transform: uppercase;
    --generator-button-background: var(--color-primary);
    --generator-button-text-color: var(--color-on-primary);
    --generator-button-font-size: 16px;
    --generator-button-small-font-size: 14px;
    --generator-button-small-padding: 8px 16px;

    --generator-border-divider: unset;
    --generator-border-divider-opacity: unset;
    --generator-border-divider-trivial: 1px solid var(--color-primary);

    --generator-text-text-align: left;

    --generator-heading-text-align: center;
    --generator-heading-font-weight: bold;
    --generator-heading-color: var(--color-primary);
    --generator-heading-3-color: var(--color-on-surface);
    --generator-heading-3-color-lighter: hsla(
        var(--color-on-surface-h),
        var(--color-on-surface-s),
        var(--color-on-surface-l),
        calc(var(--color-on-surface-a) * 0.7)
    );
    --generator-heading-3-size: clamp(22px, 1.5rem, 1.5rem);
    --generator-heading-2-size: clamp(32px, 3rem, 3rem);

    --generator-menu-shadow: unset;
    --generator-menu-font-weight: unset;
    --generator-menu-text-transform: unset;
    --generator-menu-background: var(--color-background);
    --generator-menu-letter-spacing: var(--generator-letter-spacing-widest);
    --generator-menu-text-color: var(--color-on-background);

    --generator-header-text-order: -1;
    --generator-header-heading-2-font: var(--font-heading);
    --generator-header-heading-2-font-size: clamp(18px, 2rem, 28px);
    --generator-header-heading-2-opacity: 0.7;
    --generator-header-heading-1-font-weight: normal;
    --generator-header-heading-1-letter-spacing: 0.3rem;
    --generator-header-heading-1-size: 4rem;
    --generator-header-heading-1-margin-bottom: 0.4rem;
    --generator-header-image-border-radius: unset;
    --generator-header-image-wrapper-padding: unset;
    --generator-header-image-wrapper-border-radius: unset;
    --generator-header-image-border: unset;
}

.theme5 {
    @extend .variables-px;
    @extend .default;

    --generator-module-spacing-bottom: var(--generator-spacing-400);
    --generator-module-heading-padding: var(--generator-spacing-400) 0 var(--generator-spacing-100) 0;

    --generator-page-content-max-width: 600px; // This also needs to be changed in home.module.scss
    --generator-page-wrapper-background: var(--color-surface);
    --generator-page-wrapper-padding: var(--generator-spacing-150);
    --generator-page-wrapper-border-radius: var(--generator-spacing-300);
    --generator-page-margin-top: var(--generator-spacing-300);

    --generator-content-box-background: unset;
    --generator-content-box-padding: var(--generator-spacing-200) 0;
    --generator-content-box-padding-large: var(--generator-spacing-200) 0;
    --generator-content-box-shadow: unset;
    --generator-content-box-text-color: var(--color-on-surface);

    --generator-border-divider: dotted 5px var(--color-primary);
    --generator-border-divider-opacity: 0.5;
    --generator-border-divider-trivial: dashed 1px var(--color-primary);

    --generator-image-radius: var(--generator-border-radius-md);

    --generator-button-radius: var(--generator-border-radius-md);
    --generator-button-width: 100%;
    --generator-button-padding: var(--generator-spacing-100);
    --generator-button-font-weight: bold;
    --generator-button-letter-spacing: var(--generator-letter-spacing-wide);
    --generator-button-text-transform: unset;
    --generator-button-background: var(--color-primary);
    --generator-button-text-color: var(--color-on-primary);
    --generator-button-font-size: 16px;
    --generator-button-small-font-size: 14px;
    --generator-button-small-padding: 8px 16px;

    --generator-textfields-radius: var(--generator-border-radius-md);

    --generator-text-text-align: center;

    --generator-label-text-align: center;
    --generator-label-color: var(--color-primary);
    --generator-label-font-size: 18px;
    --generator-label-margin-bottom: var(--generator-spacing-025);
    --generator-label-font-family: var(--font-heading);

    --generator-input-padding: var(--generator-spacing-100);
    --generator-input-border: 1px solid var(--color-primary);
    --generator-input-border-bottom: 1px solid var(--color-primary);
    --generator-input-background: transparent;
    --generator-input-text-color: var(--color-on-surface);

    --generator-heading-text-align: center;
    --generator-heading-font-weight: bold;
    --generator-heading-color: var(--color-primary);
    --generator-heading-3-color: var(--color-primary);
    --generator-heading-3-color-lighter: hsla(
        var(--color-primary-h),
        var(--color-primary-s),
        var(--color-primary-l),
        calc(var(--color-primary-a) * 0.7)
    );
    --generator-heading-3-size: 22px;
    --generator-heading-2-size: 36px;

    --generator-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);

    --generator-menu-shadow: rgba(0, 0, 0, 0.2) 0px 1px 10px;
    --generator-menu-background: var(--color-background);
    --generator-menu-letter-spacing: var(--generator-letter-spacing-widest);
    --generator-menu-text-color: var(--color-on-background);
    --generator-menu-font-weight: bold;
    --generator-menu-text-transform: uppercase;

    --generator-header-heading-1-font-weight: normal;
    --generator-header-heading-1-size: 60px;
    --generator-header-text-order: unset;

    --generator-header-heading-2-font: var(--font-text);
    --generator-header-heading-2-font-size: 16px;
    --generator-header-heading-2-color: var(--color-primary);
    --generator-header-heading-2-opacity: unset;
    --generator-header-image-wrapper-padding: var(--generator-spacing-100);
    --generator-header-image-wrapper-border-radius: var(--generator-spacing-200);
    --generator-header-image-border: var(--generator-border-divider);
    --generator-header-image-max-width-medium: 360px;
    --generator-header-image-max-width-small: 260px;

    --generator-header-image-border-radius: var(--generator-spacing-100);
}

.theme7 {
    @extend .variables-rem;
    @extend .default;

    --generator-module-max-width: 1000px;
    --generator-module-spacing: unset;
    --generator-module-spacing-bottom: var(--generator-spacing-300);
    --generator-module-heading-padding: var(--generator-spacing-200) 0 var(--generator-spacing-100) 0;

    --generator-page-content-max-width: 1000px;
    --generator-page-wrapper-background: transparent;
    --generator-page-wrapper-padding: 16px;
    --generator-page-wrapper-border-radius: unset;
    --generator-page-margin-top: 0;

    --generator-content-box-background: unset; 
    --generator-content-box-padding: var(--generator-spacing-100) 0;
    --generator-content-box-padding-large: 32px 0;
    --generator-content-box-shadow: var(--generator-shadow);
    --generator-content-box-text-color: var(--color-on-surface);

    --generator-image-radius: 2px;

    --generator-textfields-radius: 1px;

    --generator-label-text-align: left;
    --generator-label-color: unset;
    --generator-label-font-size: 14px;
    --generator-label-margin-bottom: 4px;

    --generator-input-padding: 1rem;
    --generator-input-border: 1px solid var(--color-primary);
    --generator-input-border-bottom: 1px solid var(--color-primary);
    --generator-input-background: rgba(0, 0, 0, 0.03);
    --generator-input-text-color: var(--color-on-surface);

    --generator-button-radius: 2px;
    --generator-button-width: auto;
    --generator-button-padding: 12px 48px;
    --generator-button-font-weight: unset;
    --generator-button-letter-spacing: var(--generator-letter-spacing-widest);
    --generator-button-text-transform: uppercase;
    --generator-button-background: var(--color-primary);
    --generator-button-text-color: var(--color-on-primary);
    --generator-button-font-size: 16px;
    --generator-button-small-font-size: 14px;
    --generator-button-small-padding: 8px 16px;

    --generator-border-divider: solid 5px var(--color-primary);
    --generator-border-divider-opacity: unset;
    --generator-border-divider-trivial: 1px solid var(--color-primary);

    --generator-text-text-align: left;

    --generator-heading-text-align: center;
    --generator-heading-font-weight: bold;
    --generator-heading-color: var(--color-primary);
    --generator-heading-3-color: var(--color-on-surface);
    --generator-heading-3-color-lighter: hsla(
        var(--color-on-surface-h),
        var(--color-on-surface-s),
        var(--color-on-surface-l),
        calc(var(--color-on-surface-a) * 0.7)
    );
    --generator-heading-3-size: clamp(22px, 1.5rem, 1.5rem);
    --generator-heading-2-size: clamp(32px, 3rem, 3rem);

    --generator-menu-shadow: unset;
    --generator-menu-font-weight: bold;
    --generator-menu-text-transform: unset;
    --generator-menu-background: unset;
    --generator-menu-letter-spacing: unset;
    --generator-menu-text-color: rgba(0, 0, 0, 0.7);

    --generator-header-text-order: unset;
    --generator-header-heading-2-font: var(--font-heading);
    --generator-header-heading-2-font-size: 2rem;
    --generator-header-heading-2-opacity: 1;
    --generator-header-heading-1-font-weight: bold;
    --generator-header-heading-1-letter-spacing: 0;
    --generator-header-heading-1-size: 4rem;
    --generator-header-image-border-radius: 2px;
    --generator-header-image-wrapper-padding: unset;
    --generator-header-image-wrapper-border-radius: unset;
    --generator-header-image-border: unset;
    --generator-header-heading-2-color: var(--color-on-primary);
    --generator-header-heading-color: var(--color-on-primary);
    --generator-header-image-max-width-medium: 600px;
    --generator-header-image-max-width-small: 400px;
    //--generator-header-image-filter: sepia(0.7);
}

.theme8 {
    @extend .variables-rem;
    @extend .default;

    --generator-module-max-width: 1000px;
    --generator-module-spacing: 0 16px;
    --generator-module-spacing-bottom: var(--generator-spacing-300);
    --generator-module-heading-padding: var(--generator-spacing-200) 0 var(--generator-spacing-100) 0;

    --generator-page-content-max-width: 100%;
    --generator-page-wrapper-background: transparent;
    --generator-page-wrapper-padding: 0;
    --generator-page-wrapper-border-radius: unset;

    --generator-content-box-background: unset;
    --generator-content-box-padding: var(--generator-spacing-100) 0;
    --generator-content-box-padding-large: 32px 0;
    --generator-content-box-shadow: var(--generator-shadow);
    --generator-content-box-text-color: var(--color-on-surface);

    --generator-image-radius: 2px;

    --generator-textfields-radius: 1px;

    --generator-label-text-align: left;
    --generator-label-color: unset;
    --generator-label-font-size: 14px;
    --generator-label-margin-bottom: 4px;

    --generator-input-padding: 1rem;
    --generator-input-border: 1px solid var(--color-primary);
    --generator-input-border-bottom: 1px solid var(--color-primary);
    --generator-input-background: rgba(0, 0, 0, 0.03);
    --generator-input-text-color: var(--color-on-surface);

    --generator-button-radius: 48px;
    --generator-button-width: auto;
    --generator-button-padding: 12px 48px;
    --generator-button-font-weight: unset;
    --generator-button-letter-spacing: var(--generator-letter-spacing-widest);
    --generator-button-text-transform: uppercase;
    --generator-button-background: var(--color-primary);
    --generator-button-text-color: var(--color-on-primary);
    --generator-button-font-size: 16px;
    --generator-button-small-font-size: 14px;
    --generator-button-small-padding: 8px 16px;

    --generator-border-divider: unset;
    --generator-border-divider-opacity: unset;
    --generator-border-divider-trivial: 1px solid var(--color-primary);

    --generator-text-text-align: center;

    --generator-heading-text-align: center;
    --generator-heading-font-weight: bold;
    --generator-heading-color: var(--color-primary);
    --generator-heading-3-color: var(--color-on-surface);
    --generator-heading-3-color-lighter: hsla(
        var(--color-on-surface-h),
        var(--color-on-surface-s),
        var(--color-on-surface-l),
        calc(var(--color-on-surface-a) * 0.7)
    );
    --generator-heading-3-size: clamp(22px, 1.5rem, 1.5rem);
    --generator-heading-2-size: clamp(32px, 3rem, 3rem);

    --generator-menu-shadow: unset;
    --generator-menu-font-weight: bold;
    --generator-menu-text-transform: unset;
    --generator-menu-background: var(--color-background);
    --generator-menu-letter-spacing: unset;
    --generator-menu-text-color: var(--color-on-background);

    --generator-header-text-order: -1;
    --generator-header-heading-2-font: var(--font-heading);
    --generator-header-heading-2-font-size: 2rem;
    --generator-header-heading-2-opacity: 1;
    --generator-header-heading-1-font-weight: bold;
    --generator-header-heading-1-letter-spacing: 0;
    --generator-header-heading-1-size: 4rem;
    --generator-header-image-border-radius: 2px;
    --generator-header-image-wrapper-padding: unset;
    --generator-header-image-wrapper-border-radius: unset;
    --generator-header-image-border: unset;
    --generator-header-heading-2-color: var(--color-on-primary);
    --generator-header-heading-color: var(--color-on-primary);
    --generator-header-image-max-width-large: 400px;
    --generator-header-image-max-width-medium: 300px;
    --generator-header-image-max-width-small: 200px;
    //--generator-header-image-filter: sepia(0.7);
}

.theme9 {
    @extend .variables-rem;
    @extend .default;

    --generator-module-max-width: 1000px;
    --generator-module-spacing: 0 16px;
    --generator-module-spacing-bottom: var(--generator-spacing-300);
    --generator-module-heading-padding: var(--generator-spacing-200) 0 var(--generator-spacing-100) 0;

    --generator-page-content-max-width: 100%;
    --generator-page-wrapper-background: transparent;
    --generator-page-wrapper-padding: 0;
    --generator-page-wrapper-border-radius: unset;

    --generator-content-box-background: unset;
    --generator-content-box-padding: var(--generator-spacing-100) 0;
    --generator-content-box-padding-large: 32px 0;
    --generator-content-box-shadow: var(--generator-shadow);
    --generator-content-box-text-color: var(--color-on-surface);

    --generator-image-radius: 2px;

    --generator-textfields-radius: 1px;

    --generator-label-text-align: left;
    --generator-label-color: unset;
    --generator-label-font-size: 14px;
    --generator-label-margin-bottom: 4px;

    --generator-input-padding: 1rem;
    --generator-input-border: 1px solid var(--color-primary);
    --generator-input-border-bottom: 1px solid var(--color-primary);
    --generator-input-background: rgba(0, 0, 0, 0.03);
    --generator-input-text-color: var(--color-on-surface);

    --generator-button-radius: 3px;
    --generator-button-width: auto;
    --generator-button-padding: 12px 48px;
    --generator-button-font-weight: bold;
    --generator-button-letter-spacing: 0.5px;
    --generator-button-text-transform: uppercase;
    --generator-button-background: var(--color-primary);
    --generator-button-text-color: var(--color-on-primary);
    --generator-button-font-size: 16px;
    --generator-button-small-font-size: 14px;
    --generator-button-small-padding: 8px 16px;

    --generator-border-divider: unset;
    --generator-border-divider-opacity: unset;
    --generator-border-divider-trivial: 1px solid var(--color-primary);

    --generator-text-text-align: center;

    --generator-heading-text-align: center;
    --generator-heading-font-weight: bold;
    --generator-heading-color: var(--color-primary);
    --generator-heading-3-color: var(--color-on-surface);
    --generator-heading-3-color-lighter: hsla(
        var(--color-on-surface-h),
        var(--color-on-surface-s),
        var(--color-on-surface-l),
        calc(var(--color-on-surface-a) * 0.7)
    );
    --generator-heading-3-size: 18px;
    --generator-heading-3-letter-spacing: -0.5px;
    --generator-heading-2-size: clamp(32px, 3rem, 3rem);
    --generator-heading-3-font-family: var(--font-text);

    --generator-menu-shadow: unset;
    --generator-menu-font-weight: bold;
    --generator-menu-text-transform: unset;
    --generator-menu-background: var(--color-background);
    --generator-menu-letter-spacing: unset;
    --generator-menu-text-color: var(--color-on-background);

    --generator-header-text-order: -1;
    --generator-header-heading-2-font: var(--font-text);
    --generator-header-heading-2-font-size: 1.5rem;
    --generator-header-heading-2-opacity: 1;
    --generator-header-heading-1-font-weight: normal;
    --generator-header-heading-1-letter-spacing: 0;
    --generator-header-heading-1-size: clamp(2rem, 5rem, 12vw);
    --generator-header-image-border-radius: 2px;
    --generator-header-image-wrapper-padding: unset;
    --generator-header-image-wrapper-border-radius: unset;
    --generator-header-image-border: unset;
    --generator-header-heading-2-color: var(--color-on-primary);
    --generator-header-heading-color: var(--color-on-primary);
    --generator-header-image-max-width-large: 100%;
    --generator-header-image-max-width-medium: 100%;
    --generator-header-image-max-width-small: 800px;
    //--generator-header-image-filter: sepia(0.7);
}

.theme10 {
    @extend .variables-rem;
    @extend .default;

    --generator-module-max-width: 800px;
    --generator-module-spacing: 0 16px;
    --generator-module-spacing-bottom: var(--generator-spacing-300);
    --generator-module-heading-padding: var(--generator-spacing-200) 0 var(--generator-spacing-100) 0;

    --generator-page-content-max-width: unset;
    --generator-page-wrapper-background: transparent;
    --generator-page-wrapper-padding: 0;
    --generator-page-wrapper-border-radius: unset;
    --generator-page-margin-top: 0;

    --generator-content-box-background: unset;
    --generator-content-box-padding: var(--generator-spacing-100) 0;
    --generator-content-box-padding-large: 32px 0;
    --generator-content-box-shadow: unset;
    --generator-content-box-text-color: var(--color-on-surface);

    --generator-image-radius: 0;

    --generator-textfields-radius: 0;

    --generator-label-text-align: left;
    --generator-label-color: var(--color-on-surface);
    --generator-label-font-size: 14px;
    --generator-label-margin-bottom: 4px;

    --generator-input-padding: 8px;
    --generator-input-border: 0px;
    --generator-input-border-bottom: 2px solid var(--color-primary);
    --generator-input-background: rgba(0, 0, 0, 0.03);
    --generator-input-text-color: var(--color-on-surface);

    --generator-button-radius: 2px;
    --generator-button-width: auto;
    --generator-button-padding: 12px 48px;
    --generator-button-font-weight: unset;
    --generator-button-letter-spacing: var(--generator-letter-spacing-widest);
    --generator-button-text-transform: uppercase;
    --generator-button-background: var(--color-primary);
    --generator-button-text-color: var(--color-on-primary);
    --generator-button-font-size: 16px;
    --generator-button-small-font-size: 14px;
    --generator-button-small-padding: 8px 16px;

    --generator-border-divider: unset;
    --generator-border-divider-opacity: unset;
    --generator-border-divider-trivial: 1px solid var(--color-primary);

    --generator-text-text-align: center;

    --generator-heading-text-align: center;
    --generator-heading-font-weight: bold;
    --generator-heading-color: var(--color-on-primary);
    --generator-heading-3-color: var(--color-on-surface);
    --generator-heading-3-color-lighter: hsla(
        var(--color-on-surface-h),
        var(--color-on-surface-s),
        var(--color-on-surface-l),
        calc(var(--color-on-surface-a) * 0.7)
    );
    --generator-heading-3-font-family: var(--font-text);
    --generator-heading-3-size: clamp(22px, 1.5rem, 1.5rem);
    --generator-heading-2-size: clamp(22px, 1.5rem, 1.5rem);

    --generator-menu-shadow: unset;
    --generator-menu-font-weight: unset;
    --generator-menu-text-transform: unset;
    --generator-menu-background: var(--color-background);
    --generator-menu-letter-spacing: var(--generator-letter-spacing-widest);
    --generator-menu-text-color: var(--color-on-background);

    --generator-header-text-order: -1;
    --generator-header-heading-2-font: var(--font-text);
    --generator-header-heading-2-font-size: clamp(22px, 1.5rem, 1.5rem);
    --generator-header-heading-2-opacity: 1;
    --generator-header-heading-1-font-weight: normal;
    --generator-header-heading-1-letter-spacing: 0rem;
    --generator-header-heading-1-size: 4rem;
    --generator-header-heading-1-margin-bottom: 0.4rem;
    --generator-header-image-border-radius: unset;
    --generator-header-image-wrapper-padding: unset;
    --generator-header-image-wrapper-border-radius: unset;
    --generator-header-image-border: unset;
    --generator-header-heading-color: var(--color-primary);

    --generator-header-image-max-width-medium: 768px;
    --generator-header-image-max-width-small: 500px;
}

.theme11 {
    @extend .variables-px;
    @extend .default;

    --generator-module-spacing-bottom: var(--generator-spacing-300);
    --generator-module-heading-padding: var(--generator-spacing-300) 0 var(--generator-spacing-200) 0;

    --generator-page-content-max-width: 600px;
    --generator-page-wrapper-background: var(--color-surface);
    --generator-page-wrapper-padding: var(--generator-spacing-150);
    --generator-page-wrapper-border-radius: 2px;
    --generator-page-margin-top: var(--generator-spacing-300);

    --generator-content-box-background: unset;
    --generator-content-box-padding: var(--generator-spacing-200) 0;
    --generator-content-box-padding-large: var(--generator-spacing-200) 0;
    --generator-content-box-shadow: unset;
    --generator-content-box-text-color: var(--color-on-surface);

    --generator-border-divider: dotted 5px var(--color-primary);
    --generator-border-divider-opacity: 0.5;
    --generator-border-divider-trivial: 0;

    --generator-image-radius: 2px;

    --generator-button-radius: 2px;
    --generator-button-width: 100%;
    --generator-button-padding: var(--generator-spacing-100);
    --generator-button-font-weight: normal;
    --generator-button-letter-spacing: var(--generator-letter-spacing-wide);
    --generator-button-text-transform: unset;
    --generator-button-background: var(--color-primary);
    --generator-button-text-color: var(--color-on-primary);
    --generator-button-font-size: 16px;
    --generator-button-small-font-size: 14px;
    --generator-button-small-padding: 8px 16px;

    --generator-textfields-radius: 2px;

    --generator-text-text-align: center;

    --generator-label-text-align: center;
    --generator-label-color: var(--color-primary);
    --generator-label-font-size: 16px;
    --generator-label-margin-bottom: var(--generator-spacing-025);
    --generator-label-font-family: var(--font-text);

    --generator-input-padding: var(--generator-spacing-100);
    --generator-input-border: 1px solid var(--color-primary);
    --generator-input-border-bottom: 1px solid var(--color-primary);
    --generator-input-background: transparent;
    --generator-input-text-color: var(--color-on-surface);

    --generator-heading-text-align: center;
    --generator-heading-font-weight: bold;
    --generator-heading-color: var(--color-primary);
    --generator-heading-3-color: var(--color-primary);
    --generator-heading-3-color-lighter: hsla(
        var(--color-primary-h),
        var(--color-primary-s),
        var(--color-primary-l),
        calc(var(--color-primary-a) * 0.7)
    );
    --generator-heading-3-size: clamp(22px, 1.5rem, 1.5rem);
    --generator-heading-2-size: clamp(28px, 2rem, 2rem);

    --generator-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);

    --generator-menu-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    --generator-menu-background: var(--color-background);
    --generator-menu-letter-spacing: var(--generator-letter-spacing-widest);
    --generator-menu-text-color: var(--color-on-background);
    --generator-menu-font-weight: normal;
    --generator-menu-text-transform: uppercase;

    --generator-header-heading-1-font-weight: normal;
    --generator-header-heading-1-size: 3rem;
    --generator-header-text-order: unset;

    --generator-header-heading-2-font: var(--font-text);
    --generator-header-heading-2-font-size: 16px;
    --generator-header-heading-2-color: var(--color-on-primary);
    --generator-header-heading-2-opacity: unset;
    --generator-header-image-wrapper-padding: 0;
    --generator-header-image-wrapper-border-radius: 0;
    --generator-header-image-border: 0;
    --generator-header-image-max-width-medium: 600px;
    --generator-header-image-max-width-small: 300px;

    --generator-header-image-border-radius: 0;
}