@import "../../../style/variables.scss";

.error {
    background: #eee;
    width: 100%;
    min-height: 120px;
    display: block;
    text-align: center;
    padding: $baseline * 2;
}

.image {
    width: 100%; // Always set image to 100% width. To common support question. If many users wants to be able to upload smaller images, fix this again later on in another way.
}

.clickable {
    cursor: pointer;

    &:focus {
        outline: $editable-outline-focus;
    }

    &:hover {
        outline: $editable-outline-hover;
    }
}
