@import "../../style/variables.scss";

.wrapper {
	margin-bottom: $baseline;
}
.topRow {
	display: flex;
	justify-content: space-between;
}

.title {
    font-weight: bold;
	font-size: 18px;
}

.back, .close {
    background: transparent;
    border: 0;
    color: #eee;
    //text-transform: uppercase;
	letter-spacing: 1px;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;

    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
    margin: 0;
    line-height: normal;
    gap: 5px;

    svg {
        display: block;
        height: $baseline;
    }

    transition: transform 0.2s ease;

    &:hover {
        transform: scale(1.1);
    }

    &:focus {
        color: #fff;
        outline: none;
        transform: scale(1.1);
    }
}

.back {
	margin-bottom: $baseline / 2;
}

.tabsWrapper {
	margin: ($baseline / 2) 0 0 0;
}
