@import "../../style/variables.scss";

.component-close {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;

    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
        display: block;
        height: $baseline;
        margin-left: 5px;
    }
}
