@import "../../../../style/variables.scss";

.wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .innerWrapper {
        padding: $baseline * 2;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px;
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
        border-radius: 2px;
        background: #fff;

        input {
            width: 100%;
        }

        .lockIcon {
            width: $baseline * 2;
            margin: 0 auto;
            margin-bottom: $baseline;
            svg {
                color: #6b6b6b;
            }
        }

        .submitButtonWrapper {
            justify-content: center;
            display: flex;

            .submitButton {
                background: #7cb342;
                border: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: $baseline 0 0 0;
                border-radius: 2px;
                width: $baseline * 10;
                height: 36px;

                svg {
                    height: $baseline * 1.5;
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
    }
}
