@import "../../style/variables.scss";

.wrapper {

    .qrCodeWrapper {
        .qrCode {
            border: 5px solid #fff;
            border-radius: 2px;
            margin-left: $baseline;
            display: block;
            float: right;
            line-height: 0;
            width: 80px;
            max-width: 80px;
        }
    }

    .text {
        font-size: 14px;
    }
}
