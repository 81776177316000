@import "../../../style/variables.scss";

.error {
    background: #eee;
    width: 100%;
    min-height: 120px;
    display: block;
    text-align: center;
    padding: $baseline * 2;
}

.clickable {
    cursor: pointer;

    &:focus {
        outline: $editable-outline-focus;
    }

    &:hover {
        outline: $editable-outline-hover;
    }
}
