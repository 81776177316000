@import "../../../style/variables.scss";

.wrapper {
    .label {
        margin-bottom: 2px;
    }

    .input {
        border-radius: 3px;
        font-family: $font-standard;
        width: 100%;
        padding: 0 $baseline / 2;
        min-height: 38px;
        font-size: $baseline;
        background: transparent;
        transition: border 0.1s ease-in;

        &:valid ~ [data-invalid-feedback] {
            display: none;
        }

        &:focus {
            outline: 0;
        }
    }

    &.light {
        input {
            border: 1px solid #ddd;
            color: #333;

            &:hover {
                border: 1px solid #b9b9b9;
            }

            &:focus {
                border: 1px solid #b9b9b9;
            }
        }
    }

    &.dark {
        input {
            border: 1px solid #575757;
            color: #ddd;
            color-scheme: dark;

            &:hover {
                border: 1px solid #828282;
            }

            &:focus {
                border: 1px solid #828282;
            }
        }
    }

    :global(.invalidFeedback:not(:empty)) {
        color: $color-form-error;
        margin-top: 3px;
        font-size: 12px;
    }
}
