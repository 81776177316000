@import "../../style/variables.scss";

.wrapper {
    max-width: 500px;
    margin: $baseline auto;
    padding: $baseline * 1 ($baseline * 2);
    font-family: $font-standard;
    border-radius: 2px;
    background: #252526;
    border-radius: 4px;
    color: #FFF;

    h1 {
        font-family: $font-standard !important;
        font-size: 24px;
    }
}