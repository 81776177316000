@import "../style/variables.scss";

.wrapper {
    position: relative;

    &:last-child {
        padding-bottom: 0;
    }

    .hiddenInfoText {
        text-align: center;
        font-size: 12px;
        display: flex;
        justify-content: center;
        position: absolute;
        top: $baseline * 2.5;
        width: 100%;
        & > * {
            background: rgba(255, 255, 255, 0.9);
            border-radius: 3px;
            padding: $baseline / 4;
            color: #ff5722;
        }
    }

    &.highlight {
        &:after {
            content: "";
            position: absolute;
            top: -4px;
            left: 4px;
            width: calc(100% - 8px);
            height: calc(100% + 8px);
            border: 2px dashed #f55385;
        }
    }

    .scrollToId {
        position: absolute;
        top: -($menu-height);
        @media (min-width: $breakpoint-l) {
            top: -($menu-height-large);
        }
    }

    .scrollToIdWithActionBar {
        position: absolute;
        top: -($action-bar-height + $menu-height);
        @media (min-width: $breakpoint-l) {
            top: -($action-bar-height + $menu-height-large);
        }
    }
}
