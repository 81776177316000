@import "../../../style/variables.scss";

.highlighted {
    &:after {
        content: "";
        position: absolute;
        top: -4px;
        left: -4px;
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        outline: 2px dashed #f55385;
    }
}

%themeBase {
    background: var(--generator-content-box-background);
    box-shadow: var(--generator-content-box-shadow);   
    position: relative; 

    .imageWrapper {
        width: 100%;

        img {
            margin: 0 auto;
            display: block;
            border-radius: var(--generator-image-radius);
        }
    }

    .textAndHeadingContentWrapper {
        padding: var(--generator-content-box-padding);
        text-align: var(--generator-text-text-align);
        color: var(--generator-content-box-text-color);

        h3 {
            margin: 0 0 var(--generator-spacing-050) 0;
            font-size: var(--generator-heading-3-size);
            font-family: var(--generator-heading-3-font-family);
            letter-spacing: var(--generator-heading-3-letter-spacing);
            color: var(--generator-heading-3-color);
            font-weight: var(--generator-heading-font-weight);
        }
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5, .theme11,
.theme6 {
    @extend %themeBase;
}
