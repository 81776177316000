@import "../../../style/variables.scss";

.wrapper {
    background: #fff;
    position: relative;
    padding: $baseline;
    width: 100%;
    cursor: pointer;

    text-align: center;

    .picker {
        position: absolute;
        bottom: 100%;
        margin-bottom: $baseline / 2;
    }

    .titleWrapper {
        background: rgba(0, 0, 0, 0.5);
        padding: ($baseline / 3);
        line-height: 13px;
        margin: $baseline / 2;
        border-radius: 2px;
        display: inline-block;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: bold;
        cursor: pointer;
    }
}
