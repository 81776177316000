@import "../../style/variables.scss";

.wrapper {
    display: flex;
    flex-wrap: wrap;
    //margin: ($baseline / 2) 0;

    .optionsWrapper {
        width: 100%;
        position: relative;

        .addNewYesOrNoInactive {
            filter: blur(2px);
            opacity: 0.8;
            position: relative;
            &:after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .addNewYesOrNoButton {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            margin: 0 auto ($baseline) auto;
        }
    }
}
