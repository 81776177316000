@import "../../style/variables.scss";

%themeBase {
    padding: var(--generator-module-spacing);
    padding-bottom: var(--generator-module-spacing-bottom);

    .innerWrapper {
        max-width: var(--generator-module-max-width);
        margin: 0 auto;
        .textAndFormWrapper {
            background: var(--generator-content-box-background);
            box-shadow: var(--generator-content-box-shadow);
            color: var(--generator-content-box-text-color);
        }

        .form {
            margin-top: var(--generator-spacing-100);
        }

        .rteWrapper {
            text-align: var(--generator-text-text-align);
        }

        .editOrShowFormButtonWrapper {
            min-height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: var(--generator-spacing-100);
        }

        .buttonWrapper {
            margin-top: var(--generator-spacing-200);
        }
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5,
.theme11,
.theme6 {
    @extend %themeBase;
}

.theme4 {
    .innerWrapper {
        .textAndFormWrapper {
            padding: var(--generator-content-box-padding);
        }
    }
}

