@import "../../style/variables.scss";

.colors {
    .colorsColumn {
        width: 100%;

        margin: 0 0 ($baseline * 2) 0;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
