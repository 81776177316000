@import "../../../../../../style/variables.scss";


.languageList {
    margin: 0;
    padding: 0;
    list-style-type: none;

    & > li {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .languageTitle {
            text-transform: capitalize;
        }
    }
}

.delete {
    background: transparent;
    white-space: nowrap;
    border: 0;
    color: #8c8c8c;
    font-size: 12px;
    padding: ($baseline / 2);
    font-weight: bold;
    cursor: pointer;
    display: flex;
    gap: $baseline / 2;
    justify-content: center;
    align-items: center;
    border-radius: 2px;

    outline: none !important;

    & > svg {
        height: $baseline * 1;
    }

    @media (max-width: $breakpoint-s) {
        .text {
            display: none;
        }
    }

    span {
        display: block;
    }

    &:hover {
        color: #3f3f3f;
    }
}

.addNewLanguageWrapper {
    display: flex;
    padding: $baseline 0;
    
    align-items: flex-end;
    gap: $baseline;
    .selectWrapper {
        flex: 1;
    }
}