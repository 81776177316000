@import "../../../style/variables.scss";

.editableLink,
.editableLinkHidden {
    display: flex;
    width: 100%;
    transition: opacity 0.2s ease;
    justify-content: center;
    font-size: 14px;
    align-items: center;

    .editableLinkText {
        flex: 1;
        margin: -($baseline/2);
        padding: $baseline / 2;
    }

    .hiddenText {
        width: $baseline * 3;
    }

    .editableToggle {
        margin-right: $baseline / 2;
    }
}

.editableLinkHidden {
    .editableLinkText {
        opacity: 0.5;
    }
}
