@import "../../generator/style/variables.scss";

.wrapper {
    text-align: center;
    padding: $baseline;

    .backLink {
        border: 1px solid #0c0c0c;
        color: #0c0c0c;
        text-decoration: none;
        text-transform: uppercase;
        margin-top: $baseline;
        display: inline-block;
        line-height: 14px;
        font-size: 14px;
        padding: ($baseline / 2) $baseline;
        border-radius: 3px;

        &:hover {
            background: rgba(0, 0, 0, 0.1);
        }
    }
}
