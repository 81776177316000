@import "../../../../../../style/variables.scss";

.wrapper {
    max-width: 1600px;
    margin: $baseline auto;
    padding: 0 $baseline;

    .intro {
        font-size: 18px;
        margin-bottom: $baseline * 2;
    }

    .totalCount {
        font-size: 14px;
        font-weight: normal;
        display: block;
    }

    .answers {
        overflow: scroll;
        background: #fff;
        padding: $baseline 0;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
        font-size: 14px;
        table {
            border-collapse: collapse;
            width: 100%;
        }
        td,
        th {
            &:first-child {
                padding-left: $baseline;
            }

            &:last-child {
                padding-right: $baseline;
            }
        }

        td,
        th {
            padding: $baseline / 2;
            border-bottom: 1px solid #c0c0c0;
        }

        .thInnerWrapper {   
            display: flex;
            align-items: center;
            cursor: pointer;
            
            gap: $baseline / 2;;
            .sortIndicator, .sortIndicatorReversed {
                width: 12px;
                line-height: 0;
            }

            .sortIndicator {
                transform: rotate(180deg);
            }
        }

        tr:last-child {
            td {
                border-bottom: 0;
            }
        }

        .dateColumn {
            white-space: nowrap;
        }

        .statusYes,
        .statusNo {
            font-size: 10px;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
            border-radius: 2px;
            background: #8bc34a;
            color: rgba(0, 0, 0, 0.7);
            letter-spacing: 0.5px;
            display: inline-block;
            padding: ($baseline / 4) ($baseline / 2);
            min-width: $baseline * 3;
            white-space: nowrap;
        }

        .statusNo {
            background: #ff9800;
        }

        .deleteButton {
            background: none;
            padding: $baseline / 3;
            margin: -($baseline / 3);
            border: 0;
            cursor: pointer;
            svg {
                height: 16px;
                color: #555;
            }

            &:hover svg {
                color: #222;
            }
        }

        th {
            font-size: 13px;
            color: #4b4b4b;
            font-weight: bold;
            line-height: $baseline;
            vertical-align: bottom;
            text-align: left;

            .truncate {
                position: relative;
                max-height: $baseline * 3; /* exactly three lines */
                overflow: hidden;

                &:after {
                    content: "";
                    text-align: right;
                    position: absolute;
                    top: $baseline * 2;
                    right: 0;
                    width: 70%;
                    height: 1.2em;
                    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
                }
            }
        }
    }

    .delete {
        background: transparent;
        white-space: nowrap;
        border: 0;
        color: #222;
        font-size: 12px;
        padding: ($baseline / 2);
        cursor: pointer;
        display: flex;
        gap: $baseline / 2;
        justify-content: center;
        align-items: center;
        border-radius: 2px;

        outline: none !important;

        & > svg {
            height: $baseline * 1;
        }

        span {
            display: block;
        }

        &:hover {
            background: rgba(0,0,0,0.1);
        }
    }
}
