@import "../../../../style/variables.scss";

.wrapper {
    max-width: $content-max-width;
    margin: ($baseline * 2) auto;
    padding: 0 $baseline;

    .sectionTitle {
        font-family: $font-standard;
        margin: 0 0 ($baseline / 2) 0;
        letter-spacing: 0;
    }

    .intro {
        font-size: 18px;
    }

    .sectionFullWidth {
        text-align: center;
    }

    .receipt {
        background: #fff;
        max-width: 500px;
        margin: 0 auto;
        margin-top: $baseline * 2;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);

        padding: $baseline * 1.5;
    }

    .dnsInfo {
        font-family: "Courier New", Courier, monospace;
    }
}
