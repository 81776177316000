@import "../../../../../../style/variables.scss";

.wrapper {
    font-size: 14px;

    .noAlternativesPadding {
        padding: $baseline;
    }
    
    .label {
        padding: $baseline;
        padding-bottom: 0;
    }
    .list {
        display: flex;
        flex-direction: column;
        gap: $baseline / 2;
        padding: $baseline 0;

        &.hasMultipleLanguages {
            gap: $baseline;
        }

        .listItem {
            display: flex;
            gap: $baseline / 2;
            align-items: center;
            padding: 0 $baseline;

            .inputWrapper {
                flex: 1;
            }

            &.hasMultipleLanguages {
                border-bottom: 1px solid #3b3b3b;

                 .inputWrapper {
                    display: flex;
                    flex-direction: column;
                    gap: $baseline / 2;
                    padding-bottom: $baseline;
                }
            }

            .delete {
                button {
                    background: transparent;
                    white-space: nowrap;
                    border: 0;
                    color: $color-edit-action-text;
                    font-size: 12px;
                    padding: ($baseline / 2);
                    font-weight: bold;
                    cursor: pointer;
                    display: flex;
                    gap: $baseline / 2;
                    justify-content: center;
                    align-items: center;
                    border-radius: 2px;
    
                    outline: none !important;
    
                    & > svg {
                        height: $baseline * 1;
                    }
    
                    span {
                        display: block;
                    }
    
                    &:hover {
                        color: $color-edit-action-text-hover;
                        background: $color-edit-action-hover;
                    }
                }
            }
        }
    }

    .addButton {
        padding: $baseline;
        padding-top: 0;
    }
}