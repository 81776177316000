@import "../../../../style/variables.scss";

.block, .blockLanguages {
    width: 100%;
    margin: ($baseline) 0;
    background: #252526;
    padding: $baseline;
    border-radius: 4px;

    .title {
        margin-top: 0;
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.blockLanguages {
    padding: 0;
    .title {
        padding: $baseline;
        padding-bottom: 0;
    }
}
