@import "../../../../style/variables.scss";

.wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-m) {
        flex-direction: row;

        .formWrapper {
            flex: 1;
        }

        .mapWrapper {
            flex: 1;
            padding-left: $baseline * 2;
            min-height: 300px;
        }
    }

    .formFieldWrapper {
        margin-bottom: $baseline;
    }

    .mapWrapper {
    }
}

.bottomButtons {
    margin-top: $baseline * 2;
    display: flex;
    justify-content: flex-end;

    .doneButton {
        flex: 1;
        margin-left: $baseline;
        max-width: 200px;
    }
}
