@import "../../../style/variables.scss";

.wrapper {
    display: flex;
    width: 100%;
    align-items: center;

    .labelWrapper {
        margin-right: $baseline * 1.5;
        font-size: 14px;
    }

    .sliderWrapper {
        line-height: 0;
        flex: 1;
    }
}
