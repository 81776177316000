@import "../../style/variables.scss";

%themeBase {
    line-height: 1;
    padding: var(--generator-module-spacing);
    padding-bottom: var(--generator-module-spacing-bottom);
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5,
.theme11,
.theme6 {
    @extend %themeBase;
}

.theme8,
.theme9 {
    padding-top: var(--generator-module-spacing-bottom);
}

.theme9 {
    background: var(--color-primary);

    --color-on-background: var(--color-on-primary);
}