@import "../../../../../../../../../../style/variables.scss";

.wrapper {
    --width-in-mm: 113;
    --height-in-mm: 156; // A6 WITH bleed

    width: 100%;
    max-width: 460px;
    aspect-ratio: calc(var(--width-in-mm) / var(--height-in-mm));
    position: relative;
    container-type: size;

    --one-mm-in-width: calc(1 / var(--width-in-mm) * 100);
    --one-mm-in-height: calc(1 / var(--height-in-mm) * 100);
    --bleedSize: calc(var(--one-mm-in-width) * 4cqw);

    .cutWrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .innerWrapper {
        width: 100%;
        height: 100%;
        position: relative;
        background-color: var(--color-background);
    }

    &.preview {
        pointer-events: none;
        user-select: none;
    }

    &.hideBleed {
        .cutWrapper {
            margin: var(--bleedSize);
            width: calc(100% - (var(--bleedSize) * 2));
            height: calc(100% - (var(--bleedSize) * 2));
        }
        .innerWrapper {
            margin: calc(var(--bleedSize) * -1);
            width: calc(100% + (var(--bleedSize) * 2));
            height: calc(100% + (var(--bleedSize) * 2));
        }
    }

    &.bleedMarker .innerWrapper {
        &:after {
            position: absolute;
            top: 0;
            pointer-events: none;
            content: "";
            outline: 100px solid rgba(0, 0, 0, 0.3);
            z-index: 1;

            width: calc(100% - (var(--bleedSize) * 2));
            height: calc(100% - (var(--bleedSize) * 2));
            margin: var(--bleedSize);
        }

        &:before {
            position: absolute;
            top: 0;
            pointer-events: none;
            content: "";
            outline: 2px dashed rgba(0, 0, 0, 0.6);
            outline-offset: -1px;
            z-index: 1;

            width: calc(100% - (var(--bleedSize) * 2));
            height: calc(100% - (var(--bleedSize) * 2));
            margin: var(--bleedSize);
        }
    }

    &.cutRounded {
        &.hideBleed .cutWrapper {
            border-radius: calc(var(--one-mm-in-width) * 8cqw);
        }

        &.bleedMarker .innerWrapper:after {
            border-radius: calc(var(--one-mm-in-width) * 8cqw);
        }

        &.bleedMarker .innerWrapper:before {
            border-radius: calc(var(--one-mm-in-width) * 8cqw);
        }
    }

    &.shadow .cutWrapper {
        box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    }
}
