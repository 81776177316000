@import "../../../../style/variables.scss";
.background {
    &.SITE {
        height: 0;
        position: sticky;
        z-index: -1;
        left: 0;
        top: 0;
    
        &.editAvailable {
            top: 56px;
        }

        .imageTopRight {
            position: absolute;
            top: $menu-height;
            right: 0;
            max-height: 40vh;
            max-width: 40vw;
        }
    
        .imageBottomLeft {
            max-height: 40vh;
            max-width: 40vw;
            position: fixed;
            bottom: 0;
            transform: rotateY(180deg) rotateX(180deg);
        }
    }

    &.THUMBNAIL {
        height: 100%;
        position: relative;
        overflow: hidden;
    
        .imageBottomLeft {
            display: none;
        }
    }

    &.PRINT_PAGE_1,
    &.PRINT_PAGE_2 {
        pointer-events: none;

        .imageTopRight {
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
        }
    
        .imageBottomLeft {
            width: 50%;
            position: absolute;
            bottom: 0;
            transform: rotateY(180deg) rotateX(180deg);
        }
    }

}
