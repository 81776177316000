@import "../../style/variables.scss";

%themeBase {
    max-width: var(--generator-module-max-width);
    margin: 0 auto;
    padding: var(--generator-module-spacing);
    padding-bottom: var(--generator-module-spacing-bottom);

}

.theme4,
.theme10,
.theme5, .theme11,
.theme6,
.theme8,
.theme9,
.theme7 {
    @extend %themeBase;
}
