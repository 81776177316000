@import "../../../../../../style/variables.scss";

.wrapper {
    max-width: $content-max-width;
    margin: ($baseline * 2) auto;
    padding: 0 $baseline;

    h1 {
        text-align: center;
    }

    .contentWrapper {
        background: #fff;
        padding: $baseline * 2;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
        margin-bottom: $baseline;
    }

    .searchDomainForm {
        display: grid;
        column-gap: $baseline;
        row-gap: $baseline;
        align-items: end;
        grid-template-columns: 2fr 1fr;

        @media (min-width: $breakpoint-s) {
            grid-template-columns: 2fr 1fr 1fr;
        }
    }
    .availableTitle,
    .notAvailableTitle {
        color: #388e3c;
        font-size: 26px;
        margin-top: 0;
    }

    .notAvailableTitle {
        color: $color-form-error;
    }

    .dnsInfo {
        font-family: "Courier New", Courier, monospace;
    }
}
