@import "../../../../../../../../style/variables.scss";

.wrapper,
%themeBase {
    display: flex;
    margin: ($baseline / 2) 0;
    align-items: center;
    flex-wrap: wrap;
    position: relative;

    .radioAndTextWrapper {
        display: flex;
        align-items: center;
    }

    .radio {
        width: $baseline;
        height: $baseline;
        margin: 0;
        padding: 0;
        margin-right: $baseline / 3;
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5, .theme11,
.theme6 {
    @extend %themeBase;
}
