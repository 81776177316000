@import "../../../../../../style/variables.scss";

.wrapper {
    max-width: $content-max-width;
    margin: ($baseline * 2) auto;
    padding: 0 $baseline;

    .sectionTitle {
        font-family: $font-standard;
        margin: 0 0 ($baseline / 2) 0;
        letter-spacing: 0;
    }

    .intro {
        font-size: 18px;
    }

    .sectionFullWidth {
        text-align: center;
    }

    .section {
        background: #fff;
        padding: $baseline;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
        display: flex;

        flex-direction: column;
        text-align: center;

        @media (min-width: $breakpoint-m) {
            padding: $baseline * 1.5;
        }

        .push {
            flex: 1;
        }

        .price {
            font-size: 22px;
        }

        .extraInfo {
            margin-top: $baseline / 2;
        }

        ul {
            text-align: left;
            margin: $baseline 0;
            padding: 0;
            list-style: none;

            li {
                margin-bottom: 4px;
                svg {
                    color: #404040;
                    height: 10px;
                    display: inline-block;
                    margin-right: 5px;
                }
            }
        }
    }

    .sectionFullWidth {
        @media (min-width: $breakpoint-m) {
            grid-column: 2 span;
        }
    }

    .grid {
        display: flex;
        column-gap: $baseline * 2;
        row-gap: $baseline * 2;
        flex-wrap: wrap;
        justify-content: center;

    }
}
