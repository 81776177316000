@import "../../../../style/variables.scss";

.outerWrapper {
    &.THUMBNAIL {
        height: 100%;
        position: relative;
        overflow: hidden;
    
        .bottomLeft,
        .bottomRight {
            display: none;
        }

        .wrapper {
            transform: scale(2);

            &.CORNER_GRAPHICS_PURPLE_GREEN_FLOWERS {
                margin-left: 25%;
                margin-top: 25%;
            }
        }
    }

    &.SITE {
        height: 0;
        position: sticky;
        z-index: $z-generator-graphic;
        left: 0;
        top: 0;
    
        &.editAvailable {
            top: 56px;
        }

        .wrapper {
            pointer-events: none;
        
            img {
                max-width: none;
            }
            
            .topRight {
                position: absolute;
                overflow: hidden;
                right: 0;
                width: 90vw;
                height: 90vh;
                img {
                    position: absolute;
                    left: 100%;
                    margin-left: -11vmin;
                    margin-top: -15vmin;
                    z-index: $z-generator-graphic;
                    height: 60vmin;
                    width: auto;
                }
        
                &.CORNER_GRAPHICS_ROSE img {
                    margin-top: -35vmin;
                    margin-left: -18vmin;
                    transform: rotate(0deg);
                }
        
                &.CORNER_GRAPHICS_WATER_COLOR img {
                    transform: rotate(-105deg);
                    margin-top: -30vmin;
                    margin-right: -30vmin;
                }
        
                &.CORNER_GRAPHICS_MULTI_COLOR img {
                    transform: rotate(60deg);
                    height: 40vmin;
                    margin-left: -20vmin;
                }
        
                &.CORNER_GRAPHICS_BALLOONS img {
                    margin-top: -25vmin;
                    margin-left: -15vmin;
                    transform: rotate(-20deg);
                }
        
                &.CORNER_GRAPHICS_PURPLE_GREEN_FLOWERS img {
                    margin-left: -24vmin;
                    margin-top: -0vmin;
                    transform: rotate(100deg);
                    height: 26vmin;
                }
        
                &.CORNER_GRAPHICS_WATER_COLOR_VINTAGE_FLOWERS img {
                    margin-left: -25vmin;
                    margin-top: -20vmin;
                    transform: rotate(100deg);
                    height: 30vmin;
                }
        
                &.CORNER_GRAPHICS_GREEN_LEAFS_ARTIFICIAL img {
                    margin-left: -14vmin;
                    margin-top: -0vmin;
                    transform: rotate(0);
                    height: 20vmin;
                }
        
                &.CORNER_GRAPHICS_ARTIFICIAL_FLOWERS_RED_AND_PINK img {
                    margin-left: -21vmin;
                    margin-top: -3vmin;
                    transform: rotate(0);
                    height: 25vmin;
                }
            }
        
            .bottomLeft {
                img {
                    position: fixed;
                    margin-left: -15vmin;
                    bottom: -15vmin;
                    z-index: $z-generator-graphic;
                    height: 40vmin;
                    width: auto;
                    max-width: auto;
                    transform: rotate(180deg);
                }
        
                &.CORNER_GRAPHICS_ROSE img {
                    transform: rotate(80deg);
                    margin-left: -28vmin;
                    height: 50vmin;
                    bottom: -30vmin;
                }
        
                &.CORNER_GRAPHICS_WATER_COLOR img {
                    transform: rotate(-105deg);
                    bottom: -13vmin;
                    margin-left: -17vmin;
                }
        
                &.CORNER_GRAPHICS_MULTI_COLOR img {
                    transform: rotate(70deg);
                    height: 30vmin;
                    margin-left: -18vmin;
                    bottom: -15vmin;
                }
        
                &.CORNER_GRAPHICS_BALLOONS img {
                    transform: rotate(0deg);
                }
        
                &.CORNER_GRAPHICS_PURPLE_GREEN_FLOWERS img {
                    margin-left: -7vmin;
                    transform: rotate(-90deg);
                    bottom: 0;
                    height: 30vmin;
                }
        
                &.CORNER_GRAPHICS_WATER_COLOR_VINTAGE_FLOWERS img {
                    margin-left: -18vmin;
                    bottom: -10vmin;
                    transform: rotate(30deg);
                    height: 30vmin;
                }
        
                &.CORNER_GRAPHICS_GREEN_LEAFS_ARTIFICIAL img {
                    margin-left: -9vmin;
                    bottom: -23vmin;
                    transform: rotate(0);
                    height: 50vmin;
                }
        
                &.CORNER_GRAPHICS_ARTIFICIAL_FLOWERS_RED_AND_PINK img {
                    margin-left: -6vmin;
                    bottom: -10vmin;
                    transform: rotate(0);
                    height: 40vmin;
                }
            }
        
            .bottomRight {
                img {
                    position: fixed;
                    right: -20vmin;
                    bottom: -15vmin;
                    z-index: $z-generator-graphic;
                    height: 60vmin;
                    width: auto;
                    max-width: auto;
                    transform: rotate(180deg);
                }
        
                &.CORNER_GRAPHICS_ROSE img {
                    transform: rotate(-100deg);
                    height: 50vmin;
                    bottom: -22vmin;
                }
        
                &.CORNER_GRAPHICS_WATER_COLOR img {
                    transform: rotate(-60deg) scaleX(-1);
                    bottom: -20vmin;
                    right: -25vmin;
                }
        
                &.CORNER_GRAPHICS_MULTI_COLOR img {
                    transform: rotate(60deg);
                    height: 40vmin;
                    right: -27vmin;
                }
        
                &.CORNER_GRAPHICS_BALLOONS img {
                    transform: rotate(-20deg);
                    height: 40vmin;
                    right: -15vmin;
                }
        
                &.CORNER_GRAPHICS_PURPLE_GREEN_FLOWERS img {
                    bottom: -7vmin;
                    transform: rotate(0deg);
                    right: -5vmin;
                    height: 40vmin;
                }
        
                &.CORNER_GRAPHICS_WATER_COLOR_VINTAGE_FLOWERS img {
                    bottom: -10vmin;
                    transform: rotate(0deg);
                    right: -12vmin;
                    height: 40vmin;
                }
        
                &.CORNER_GRAPHICS_GREEN_LEAFS_ARTIFICIAL img {
                    bottom: -1vmin;
                    transform: rotate(0deg);
                    right: -1vmin;
                    height: 20vmin;
                }
        
                &.CORNER_GRAPHICS_ARTIFICIAL_FLOWERS_RED_AND_PINK img {
                    bottom: -3vmin;
                    transform: rotate(75deg);
                    right: -3vmin;
                    height: 23vmin;
                }
            }
        }
    }

    &.PRINT_PAGE_1, &.PRINT_PAGE_2 {
        height: 100%;
        width: 100%;
        top: 0;
        position: absolute;
        overflow: hidden;
        pointer-events: none;

        .wrapper {
            pointer-events: none;
        
            img {
                max-width: none;
            }

            .topRight {
                position: absolute;
                overflow: hidden;
                right: 0;
                width: 90%;
                height: 90%;

                img {
                    position: absolute;
                    left: 100%;
                    z-index: $z-generator-graphic;
                    width: auto;
                }

                &.CORNER_GRAPHICS_BLUE img {
                    margin-top: -10%;
                    margin-left: -30%;
                    transform: rotate(0deg);
                    height: 40%;
                }
        
                &.CORNER_GRAPHICS_ROSE img {
                    margin-top: -25%;
                    margin-left: -25%;
                    transform: rotate(0deg);
                    height: 40%;
                }
        
                &.CORNER_GRAPHICS_WATER_COLOR img {
                    transform: rotate(-105deg);
                    margin-top: -20%;
                    margin-left: -30%;
                    height: 40%
                }
        
                &.CORNER_GRAPHICS_MULTI_COLOR img {
                    transform: rotate(60deg);
                    height: 40%;
                    margin-left: -50%;
                    margin-top: -30%;
                }
        
                &.CORNER_GRAPHICS_BALLOONS img {
                    margin-top: -34%;
                    margin-left: -24%;
                    transform: rotate(-20deg);
                    height: 70%;
                }
        
                &.CORNER_GRAPHICS_PURPLE_GREEN_FLOWERS img {
                    margin-left: -49%;
                    margin-top: 1%;
                    transform: rotate(100deg);
                    height: 35%;
                }
        
                &.CORNER_GRAPHICS_WATER_COLOR_VINTAGE_FLOWERS img {
                    margin-left: -42%;
                    margin-top: -20%;
                    transform: rotate(100deg);
                    height: 40%;
                }
        
                &.CORNER_GRAPHICS_GREEN_LEAFS_ARTIFICIAL img {
                    margin-left: -30%;
                    margin-top: -0;
                    transform: rotate(0);
                    height: 30%;
                }
        
                &.CORNER_GRAPHICS_ARTIFICIAL_FLOWERS_RED_AND_PINK img {
                    margin-left: -51%;
                    margin-top: -4%;
                    transform: rotate(0);
                    height: 40%;
                }
            }
        
            .bottomLeft {
                img {
                    position: absolute;
                    z-index: $z-generator-graphic;
                    width: auto;
                    max-width: auto;
                    transform: rotate(180deg);
                }

                &.CORNER_GRAPHICS_BLUE img {
                    transform: rotate(80deg);
                    margin-left: -28%;
                    height: 50%;
                    bottom: -20%;
                }
        
                &.CORNER_GRAPHICS_ROSE img {
                    transform: rotate(80deg);
                    margin-left: -28%;
                    height: 50%;
                    bottom: -30%;
                }
        
                &.CORNER_GRAPHICS_WATER_COLOR img {
                    transform: rotate(-105deg);
                    height: 50%;
                    bottom: -17%;
                    margin-left: -25%;
                }
        
                &.CORNER_GRAPHICS_MULTI_COLOR img {
                    transform: rotate(70deg);
                    height: 35%;
                    margin-left: -18%;
                    bottom: -13%;
                }
        
                &.CORNER_GRAPHICS_BALLOONS img {
                    transform: rotate(20deg);
                    margin-left: -20%;
                    bottom: -24%;
                    height: 50%;
                }
        
                &.CORNER_GRAPHICS_PURPLE_GREEN_FLOWERS img {
                    margin-left: -10%;
                    transform: rotate(-90deg);
                    bottom: 0;
                    height: 40%;
                }
        
                &.CORNER_GRAPHICS_WATER_COLOR_VINTAGE_FLOWERS img {
                    margin-left: -25%;
                    bottom: -13%;
                    transform: rotate(30deg);
                    height: 42%;
                }
        
                &.CORNER_GRAPHICS_GREEN_LEAFS_ARTIFICIAL img {
                    margin-left: -11%;
                    bottom: -31%;
                    transform: rotate(0);
                    height: 70%;
                }
        
                &.CORNER_GRAPHICS_ARTIFICIAL_FLOWERS_RED_AND_PINK img {
                    margin-left: -9%;
                    bottom: -15%;
                    transform: rotate(0);
                    height: 50%;
                }
            }
        
            .bottomRight {
                img {
                    position: absolute;
                    z-index: $z-generator-graphic;
                    width: auto;
                    max-width: auto;
                    transform: rotate(180deg);
                }

                &.CORNER_GRAPHICS_BLUE img {
                    transform: rotate(180deg);
                    height: 35%;
                    bottom: -8%;
                    right: -10%;
                    display: none;
                }
        
                &.CORNER_GRAPHICS_ROSE img {
                    transform: rotate(-100deg);
                    height: 35%;
                    bottom: -8%;
                    right: -5%
                }
        
                &.CORNER_GRAPHICS_WATER_COLOR img {
                    transform: rotate(-30deg) scaleX(-1);
                    bottom: -5%;
                    right: -5%;
                    height: 30%;
                }
        
                &.CORNER_GRAPHICS_MULTI_COLOR img {
                    transform: rotate(20deg);
                    height: 30%;
                    right: -5%;
                    bottom: -10%;
                    display: none;
                }
        
                &.CORNER_GRAPHICS_BALLOONS img {
                    transform: rotate(-20deg);
                    height: 50%;
                    right: -20%;
                    bottom: -20%;
                }
        
                &.CORNER_GRAPHICS_PURPLE_GREEN_FLOWERS img {
                    display: none;
                }
        
                &.CORNER_GRAPHICS_WATER_COLOR_VINTAGE_FLOWERS img {
                    bottom: -16%;
                    transform: rotate(0deg);
                    right: -13%;
                    height: 45%;
                }
        
                &.CORNER_GRAPHICS_GREEN_LEAFS_ARTIFICIAL img {
                    bottom: -0%;
                    transform: rotate(0deg);
                    right: -1%;
                    height: 25%;
                }
        
                &.CORNER_GRAPHICS_ARTIFICIAL_FLOWERS_RED_AND_PINK img {
                    display: none;
                }
            }
        }
    }
}
