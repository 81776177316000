@import "../../style/variables.scss";

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 3px;
    padding: $baseline / 2;
    line-height: 12px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 0;
    background: $color-edit-action;
    color: $color-edit-action-text;
    -webkit-appearance: none;
    position: relative;
    transition: $color-edit-action-transition;

    svg {
        height: 12px;
        margin-right: $baseline / 2;
    }

    &:hover {
        color: $color-edit-action-text-hover;
    }

    .pointerUp,
    .pointerDown,
    .pointerRight,
    .pointerLeft {
        position: absolute;
        width: 0;
        height: 0;
    }

    .pointerUp {
        bottom: 100%;
        left: 50%;
        margin-left: -3px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;

        border-bottom: 6px solid #252526;
    }

    .pointerRight {
        top: 50%;
        left: 100%;
        margin-top: -5px;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;

        border-left: 6px solid #252526;
    }
}
