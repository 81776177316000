@import "../../style/variables.scss";

%themeBase {
    padding: var(--generator-module-spacing);
    padding-bottom: var(--generator-module-spacing-bottom);

    .innerWrapper {
        max-width: var(--generator-module-max-width);
        margin: 0 auto;
        .textAndFormWrapper {
            background: var(--generator-content-box-background);
            box-shadow: var(--generator-content-box-shadow);
            color: var(--generator-content-box-text-color);
        }

        .form {
            margin-top: var(--generator-spacing-100);
        }

        .rteWrapper {
            text-align: var(--generator-text-text-align);
        }

        .editOrSelectNumberOfPersonsWrapper {
            min-height: 82px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: var(--generator-spacing-100);
        }

        .buttonWrapper {
            margin-top: var(--generator-spacing-200);
        }
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5,
.theme11,
.theme6 {
    @extend %themeBase;
}

.theme4 {
    .innerWrapper {
        .textAndFormWrapper {
            padding: var(--generator-content-box-padding);
        }
    }
}

.theme8 {
    --gradient-from-color: hsla(
        calc(var(--color-primary-h) + 60),
        var(--color-primary-s),
        var(--color-primary-l),
        var(--color-primary-a)
    );
    background: linear-gradient(45deg, var(--gradient-from-color) 0%, var(--color-primary) 100%);

    --button-color-from-primary: hsla(
        var(--color-primary-h),
        var(--color-primary-s),
        calc(var(--color-primary-l) + 10%),
        var(--color-primary-a)
    );

    --input-color-from-surface: hsla(var(--color-surface-h), var(--color-surface-s), var(--color-surface-l), 0.7);

    --generator-button-background: var(--button-color-from-primary);
    --generator-input-background: var(--input-color-from-surface);
    --generator-heading-color: var(--color-on-primary);
    --generator-content-box-text-color: var(--color-on-primary);
    --generator-heading-3-color: var(--color-on-primary);
}

.theme9 {
    background: var(--color-primary);

    --button-color-from-primary: hsla(
        var(--color-primary-h),
        var(--color-primary-s),
        calc(var(--color-primary-l) + 10%),
        var(--color-primary-a)
    );

    --input-color-from-surface: hsla(var(--color-surface-h), var(--color-surface-s), var(--color-surface-l), 0.7);

    --generator-button-background: var(--button-color-from-primary);
    --generator-input-background: var(--input-color-from-surface);
    --generator-heading-color: var(--color-on-primary);
    --generator-content-box-text-color: var(--color-on-primary);
    --generator-heading-3-color: var(--color-on-primary);
}
