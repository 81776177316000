@import "../../../../style/variables.scss";

.wrapper {
    max-width: 500px;
    margin: ($baseline * 2) auto;
    padding: 0 $baseline;

    .sectionTitle {
        font-family: $font-standard;
        margin: 0 0 ($baseline / 2) 0;
        letter-spacing: 0;
    }

    .section {
        text-align: left;
        background: #fff;
        max-width: 500px;
        margin: 0 auto;
        padding: $baseline;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
        display: flex;
        flex-direction: column;
        gap: $baseline;
        margin-top: $baseline * 2;

        @media (min-width: $breakpoint-m) {
            padding: $baseline * 1.5;
        }
    }

    .firstButtonWrapper {
        margin-top: $baseline * 1;
    }

    .description {
        font-size: 13px;
        color: #747474;
        padding-top: $baseline / 2;
    }
}
