@import "../../style/variables.scss";

.footer {
    text-align: center;
    margin: ($baseline * 3) 0 0 0;
    padding: $baseline;
    font-size: 12px;
    color: #4a4a4a;

    .navigation {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-bottom: $baseline;

        a {
            color: #2e2e2e;
            text-decoration: none;
            padding: $baseline / 2;
            font-size: 14px;

            @media (min-width: 374px) {
                font-size: 15px;
                letter-spacing: 1px;
            }

            @media (min-width: $breakpoint-m) {
                font-size: 15px;
                letter-spacing: 1px;
                padding: $baseline;
            }

            .inner {
                border-top: 1px solid transparent;
                border-bottom: 1px solid rgba(220, 220, 220, 0);
                transition: border 300ms ease-out;
            }

            &:hover {
                .inner {
                    border-top: 1px solid transparent;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
                }
            }

            &.active {
                .inner {
                    border-top: 1px solid transparent;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.8);
                }
            }
        }
    }

    .mailLink {
        color: #4a4a4a;
    }

    .inity {
        display: inline-block;
        margin-top: $baseline;
        width: 50px;

        svg {
            height: 30px;
            width: 50px;
        }
    }
}
