@import "../../style/variables.scss";

.component-button {
    display: inline-block;
    border: 0;
    padding: ($baseline / 2) $baseline;
    min-height: 36px;
    text-decoration: none;
    border-radius: 3px;
    cursor: pointer;
    -webkit-appearance: none;

    color: rgba(0, 0, 0, 0.8);
    font-size: $baseline;
    font-weight: bold;
    //box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 0;
    transition: filter 0.2s ease-in-out;

    &.full-width {
        display: block;
        width: 100%;
        text-align: center;
    }

    &.primary {
        background: #73c991;
    }

    &.warning {
        background: #e9796b;
    }

    &.secondary {
        background: #ccc;
    }

    &:hover {
        filter: brightness(110%);
        color: rgba(0, 0, 0, 0.9);
    }
}
