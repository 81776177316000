@import "../../style/variables.scss";

.module-confirmation {
    .button-wrapper {
        display: flex;
        width: 100%;
        margin-top: $baseline;

        & > button {
            flex: 1;
            &:first-child {
                margin-right: $baseline;
            }
        }
    }
}
