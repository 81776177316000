@import "../../../../../../../../../../../../style/variables.scss";

.wrapper {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    line-height: 1.6;
    position: relative; // Needed for graphics one level up

    font-family: var(--font-text);
    color: var(--color-on-background);

    font-size: 2.8cqw;
    letter-spacing: 0.1cqw;

    .welcomeText {
        letter-spacing: 0.3cqw;
        font-size: 3cqw;
        padding: 0 6cqw;
    }

    .names {
        font-size: 11cqw;
        line-height: 1.2;
        padding: 4cqw 6cqw 5cqw 6cqw;
        font-family: var(--font-heading);
    }

    .websiteInfo {
        margin-top: 5cqw;
        padding: 0 6cqw;
    }

    .qr {
        width: 30cqw;
        line-height: 0;
        margin: 0 auto;

        .img {
            border: 1cqw solid white;
            border-radius: 2px;
        }
    }
    
    .textAtQR {
        margin-bottom: 3cqw;
        padding: 0 6cqw;
    }

    .dateAndLocationLong {
        padding: 0 6cqw;
    }
}