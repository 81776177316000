@import "../../../style/variables.scss";

.wrapper {
    display: flex;
    width: 100%;
    align-items: center;

    .labelWrapper {
        margin-right: $baseline * 1.5;
        font-size: 14px;
    }

    .pickerWrapper {
        line-height: 0;
        flex: 1;
        position: relative;

        .preview {
            cursor: pointer;
            width: $baseline * 4;
            height: $baseline * 1.5;
            border-radius: 2px;
        }

        .picker {
            position: absolute;
            bottom: 100%;
            margin-bottom: $baseline / 2;
        }
    }
}
