@import "../../style/variables.scss";

.confirmation {
    h3 {
        margin-top: 0;
        margin-bottom: $baseline / 2;
        letter-spacing: 1px;

        font-size: 22px;

        @media (min-width: $breakpoint-m) {
            font-size: 26px;
        }
    }

    .confirmationTitleError {
        color: $color-form-error;
    }

    .confirmationText {
    }
}
