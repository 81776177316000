@import "../../style/variables.scss";

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    z-index: $z-modal !important;

    .closeAndContentWrapper {
        position: relative;
        outline: 0;
    }

    .close {
        position: absolute;
        top: $baseline / 2;
        right: $baseline / 2;
        background: none;
        border: 0;
        color: #0c0c0c;
        z-index: 1;
        padding: $baseline / 2;
        margin: 0;
        line-height: 0;
        cursor: pointer;
        max-width: $baseline * 2; // IE11 fix

        svg {
            height: $baseline;
        }
    }

    .paper {
        outline: 0;
        width: 90vw;
        max-width: 500px;
        max-height: 80vh;
        position: relative;
        box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 0;
        padding: $baseline * 2;
        background: #fff;
        overflow-y: auto;
        scroll-behavior: smooth;

        @media (min-width: $breakpoint-m) {
            padding: $baseline * 3;
        }
    }
}
