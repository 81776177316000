@import "../../style/variables.scss";

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5, .theme11,
.theme6 {
    margin: 0 auto;
}

.placeholder {
    background: #eee;
    width: 100%;
    min-height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $baseline * 2;
    cursor: pointer;

    &:focus {
        outline: $editable-outline-focus;
    }

    &:hover {
        outline: $editable-outline-hover;
    }
}

.theme10 {
    img {
        filter: saturate(90%) sepia(0.15);
    }
}
