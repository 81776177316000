@import "../../style/variables.scss";

.wrapper {
    margin: 0 auto;
}

.placeholder {
    background: #eee;
    width: 100%;
    min-height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $baseline * 2;
    cursor: pointer;

    &:focus {
        outline: $editable-outline-focus;
    }

    &:hover {
        outline: $editable-outline-hover;
    }
}

.clickable {
    cursor: pointer;

    &:focus {
        outline: $editable-outline-focus;
    }

    &:hover {
        outline: $editable-outline-hover;
    }
}
