@import "../../../../style/variables.scss";

.confirmationWrapper {
    display: flex;
    gap: $baseline;
    flex-direction: column;

    @media (min-width: $breakpoint-l) {
        & > * {
            flex: 1;
        }
        flex-direction: row;
    }
 }

 .othersWrapper {
    display: flex;
    gap: $baseline;
    flex-direction: column;

    @media (min-width: $breakpoint-l) {
        & > * {
            flex: 1;
        }
        flex-direction: row;
    }
 }


