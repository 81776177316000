@import "../../../style/variables.scss";

.textFieldWrapper {
    label {
        font-size: 14px;
        margin-left: 2px;
    }

    input {
        border-radius: 3px;
        border: 1px solid #c0c0c0;

        &:valid ~ [data-invalid-feedback] {
            display: none;
        }
    }

    :global(.invalidFeedback:not(:empty)) {
        color: $color-form-error;
        margin-top: 3px;
        font-size: 12px;
    }
}

.inputClickArea {
    cursor: pointer;
}
