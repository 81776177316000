@import "../../style/variables.scss";

.addNewRowWrapper {
    display: flex;
    justify-content: center;
}

%themeBase {
    display: grid;
    grid-template-columns: 1fr;
    align-items: stretch;
    justify-items: stretch;
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5, .theme11,
.theme6 {
    @extend %themeBase;
}
