@import "../../../../style/variables.scss";

%themeBase {
    margin-top: var(--generator-spacing-300);

    &:first-child {
        margin-top: 0;
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5, .theme11,
.theme6 {
    @extend %themeBase;
}
