@import "../../style/variables.scss";

.list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    .listItem {
        padding: ($baseline / 4) 0;
        border-top: 1px solid #9e9e9e;
    }
}
