@import "../../style/variables.scss";

.selectViewWrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: $baseline * 2;
    margin-bottom: $baseline;
}

%themeBase {
    max-width: var(--generator-module-max-width);
    margin: 0 auto;
    padding: var(--generator-module-spacing);
    padding-bottom: var(--generator-module-spacing-bottom);

    .rteWrapper {
        text-align: var(--generator-text-text-align);
    }

    .contentWrapper {
        background: var(--generator-content-box-background);
        box-shadow: var(--generator-content-box-shadow);
        color: var(--generator-content-box-text-color);
    }

    .selectViewWrapper {
        height: var(--generator-spacing-150);
        margin-bottom: var(--generator-spacing-050);
    }

    .showGiftRegistryButtonWrapper {
        text-align: center;
    }

    .giftRegistryWrapper {
        margin: 0 auto;
        max-width: 600px;
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5, .theme11,
.theme6 {
    @extend %themeBase;
}

.theme4 {
    .contentWrapper {
        padding: var(--generator-content-box-padding);
    }
}
