@import "../../../../style/variables.scss";

html {
    @media (min-width: 600px) {
        font-size: 100%;
    }
}

%themeBase {
    border-radius: var(--generator-page-wrapper-border-radius);
    position: relative;
    width: 100%;
    max-width: var(--generator-page-content-max-width);
    background: var(--generator-page-wrapper-background);
    padding: var(--generator-page-wrapper-padding);
    margin: 0 auto 0 auto;
    margin-top: var(--generator-page-margin-top);
    margin-bottom: 100px; // Mainly so that graphics can fit if scrolled to bottom

    a {
        color: var(--color-primary);
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5,
.theme6,
.theme11 {
    @extend %themeBase;
}

.theme6 {
    width: 70vw;
    margin: 0 0 0 auto;
}

.theme5 {
    border-radius: 0;
    margin-top: 0;
    @media (min-width: 640px) {
        border-radius: var(--generator-page-wrapper-border-radius);
        margin-top: var(--generator-page-margin-top);
        &:before {
            background: var(--generator-page-wrapper-background);
            border-radius: var(--generator-spacing-400);
            content: "";
            width: calc(100% + 40px);
            height: calc(100% + 40px);
            left: -20px;
            top: -20px;
            opacity: 0.5;
            z-index: -1;
            position: absolute;
        }
    }
}

.theme8 {
    & > *:nth-child(odd) {
        background: hsla(var(--color-surface-h), var(--color-surface-s), var(--color-surface-l), 0.4);
    }
}

.theme9 {
    & > *:nth-child(odd) {
        background: hsla(var(--color-surface-h), var(--color-surface-s), var(--color-surface-l), 1);
    }
}

.theme11 {
    width: calc(100% - var(--generator-spacing-200));
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    @media (max-width: 500px) {
        margin-top: $baseline * 2;
        padding: var(--generator-spacing-100);
    }
}
