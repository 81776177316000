@import "../../../../style/variables.scss";


%themeBase {
    border-bottom: var(--generator-border-divider-trivial);
    margin-top: 0;
    padding-bottom: 2px;
    color: var(--generator-heading-3-color);
    letter-spacing: var(--generator-heading-3-letter-spacing);
    font-family: var(--generator-heading-3-font-family);

    .personHeadingEditable {
        display: inline-block;
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5,
.theme11,
.theme6 {
    @extend %themeBase;
}
