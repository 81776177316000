@import "../../../../../../../../../../style/variables.scss";

.wrapper {
    background: #fff;
    padding: $baseline;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
    margin-bottom: $baseline;
    font-size: 14px;

    h3 {
        margin-top: 0;
    }

    select {
        width: auto;
        max-width: 100%;
    }

    .selects {
        display: flex;
        flex-wrap: wrap;
        gap: $baseline;

        & > * {
            max-width: 100%; // Needs to be here so that select does not overflow
        }
    }
}
