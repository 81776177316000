@import "../../../style/variables.scss";

.wrapper,
%themeBase {
    display: flex;
    margin: ($baseline / 2) 0;
    align-items: center;
    flex-wrap: wrap;

    .checkboxAndTextWrapper {
        display: flex;
        align-items: center;
    }

    .checkbox {
        width: $baseline;
        height: $baseline;
        margin: 0;
        padding: 0;
        margin-right: $baseline / 3;
    }

    :global(.invalidFeedback:not(:empty)) {
        display: block;
        color: $color-form-error;
        margin-top: 3px;
        font-size: 12px;
        width: 100%;
    }

    .labelEdit {
        // Click on the checkbox should also activiate label edit
        margin-left: -22px;
        padding-left: 22px;

        &:empty:before {
            content: attr(placeholder);
            color: rgba(0, 0, 0, 0.4);
        }
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5, .theme11,
.theme6 {
    @extend %themeBase;
}
