@import "../../../style/variables.scss";

.wrapper,
.wrapperActive {
    cursor: pointer;
    background: #252526;
    padding: $baseline / 2;
    border-radius: 4px;
    line-height: 0;

    img {
        width: 100%;
        height: auto;
    }
}

.wrapperActive {
    outline: 3px solid #f35284;
}
