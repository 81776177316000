@import "../../../style/variables.scss";

.list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    @media (min-width: $breakpoint-m) {
        margin: -($baseline / 2);
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
    }

    & > li {
        background: #252526;
        padding: $baseline;
        border-radius: 2px;
        margin-bottom: $baseline;
        cursor: pointer;

        @media (min-width: $breakpoint-m) {
            width: calc(50% - #{$baseline});
            margin: $baseline / 2;
        }

        .title {
            font-size: 14px;
            margin-bottom: 4px;
            font-weight: bold;
        }
        .description {
            font-size: 12px;
        }
    }
}
