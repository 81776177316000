@import "../../style/variables.scss";

.wrapper {
    .button {
        border: 1px solid #0c0c0c;
        color: #0c0c0c;
        text-decoration: none;
        text-transform: uppercase;
        margin-top: $baseline;
        display: inline-block;
        line-height: 14px;
        font-size: 14px;
        padding: ($baseline / 2) $baseline;
        border-radius: 3px;
        background: transparent;
        cursor: pointer;

        &:hover {
            background: rgba(0, 0, 0, 0.1);
        }
    }

    .links {
        display: flex;
        flex-direction: column;
        gap: $baseline / 2;
        margin-top: $baseline * 2;
        font-size: 12px;

        a {
            text-decoration: none;
            font-size: 14px;
        }
    }

    .goToPageOrLogoutWrapper {
        display: flex;
        margin-top: $baseline;
        align-items: center;
        flex-wrap: wrap;

        @media (min-width: $breakpoint-s) {
            flex-wrap: nowrap;
        }

        .logout {
            display: inline-block;
            margin-top: $baseline;
            font-size: 12px;
            margin-left: $baseline;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
