@import "../style/variables.scss";

.addNewModuleButtonWrapper {
    position: relative;
    height: 0;

    .addNewModuleButton {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
    }
}
