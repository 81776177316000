@import "../../../style/variables.scss";

%themeBase {
    transition: background 0.2s ease;
    display: flex;
    line-height: 14px;
    text-decoration: none;
    border-radius: 3px;
}

%themeBase {
    letter-spacing: var(--generator-menu-letter-spacing);
    font-weight: var(--generator-menu-font-weight);
    text-transform: var(--generator-menu-text-transform);
    padding: 12px ($baseline / 2);
    font-size: 14px;
    color: var(--generator-menu-text-color);
    position: relative;

    &.navigationFits {
        padding: ($baseline / 2) ($baseline * 1);

        &::after {
            position: absolute;
            left: 50%;
            margin-left: -($baseline / 2);
            bottom: 0;
            width: $baseline;
            height: 2px;
            background: var(--color-primary);
            content: "";
            opacity: 0;
            transition: opacity 0.3s, transform 0.3s;
            transform: translateY($baseline + 1);
        }

        &:hover::after {
            opacity: 1;
            transform: translateY(2px);
        }
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5, .theme11,
.theme6 {
    @extend %themeBase;
}
