@import "./variables.scss";

body {
    line-height: 1.6;
    img {
        max-width: 100%;
        height: auto;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Raleway", serif;
    letter-spacing: 1px;
    line-height: 1.4;
}

textarea,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"] {
    width: 100%;
    padding: $baseline / 2;
    font-size: $baseline;
}
