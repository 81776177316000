@import "../../../../../../style/variables.scss";


.labelsWrappers {
    display: flex;
    gap: $baseline;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 350px;
    margin: 0 auto;
}
