@import "../../style/variables.scss";

.text {
    font-size: 14px;
    margin-bottom: $baseline;
}

.checkboxWrapper {
    font-size: 14px;
    margin-bottom: $baseline / 2;
}

.publishFirst {
    color: $color-form-error;
    width: 100%;
    font-size: 14px;
    margin-top: $baseline / 2;
}

.fieldAndButtonWrapper {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: $baseline;

    .textFieldWrapper {
        flex: 1;
    }

    @media (min-width: $breakpoint-m) {
        flex-wrap: nowrap;
    }
}
