@import "../../style/variables.scss";

%themeBase {
    display: flex;
    justify-content: center;
    padding: $baseline;

    .innerWrapper {
        display: flex;
        flex-wrap: wrap;
        gap: $baseline / 2;

        .button {
            text-decoration: none;
            -webkit-appearance: none;
            border: 0;

            background: var(--generator-button-background);
            border: 1px solid var(--generator-button-background);
            color: var(--generator-button-text-color);
            font-family: var(--font-text);
            font-weight: var(--generator-button-font-weight);
            letter-spacing: var(--generator-button-letter-spacing);
            font-size: var(--generator-button-font-size);
            border-radius: var(--generator-button-radius);
            padding: var(--generator-button-small-padding);
            text-align: center;
            text-transform: var(--generator-button-text-transform);

            transition: transform 250ms cubic-bezier(0.2, 0.8, 0.4, 1);

            &:focus {
                outline: 2px solid var(--generator-button-background);
                outline-offset: 2px;
            }

            cursor: pointer;

            &:hover {
                transform: scale(1.07);
            }

            &:active {
                transform: scale(0.9);
            }
        }
    }
}


.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5,
.theme11,
.theme6 {
    @extend %themeBase;
}

.theme4 {
    padding-bottom: 0;
    @media (min-width: 640px) {
        padding: 0;
        padding-top: ($baseline * 2);
    }
}

.theme5 {
    
    @media (min-width: 640px) {
        padding: 0;
        padding-top: ($baseline * 2);
    }
}

.theme7 {
    padding-top: $baseline * 2;

    @media (min-width: $breakpoint-l) {
        padding-top: $baseline * 3;
        padding-bottom: 0;
    }
}

.theme8 {
    padding-bottom: $baseline * 2;
}

.theme9 {
    @media (min-width: $breakpoint-m) {
        padding: $baseline * 2;
    }
}

.theme10 {
    padding-bottom: 0;
    @media (min-width: 640px) {
        padding: 0;
        padding-top: ($baseline * 2);
    }
}

.theme11 {
    padding-bottom: 0;
    padding-top: $baseline * 2;
    @media (min-width: 499px) {
        padding: 0;
        padding-top: ($baseline * 3);
    }
}