@import "../../style/variables.scss";

%themeBase {
    max-width: var(--generator-module-max-width);
    margin: 0 auto;
    padding: var(--generator-module-spacing);
    padding-bottom: var(--generator-module-spacing-bottom);

    .rteWrapper {
        text-align: var(--generator-text-text-align);
    }

    .contentWrapper {
        background: var(--generator-content-box-background);
        box-shadow: var(--generator-content-box-shadow);
        color: var(--generator-content-box-text-color);
    }

    .showGiftRegistryButtonAndEditButtonWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 48px;
        margin-top: var(--generator-spacing-100);
    }

    .giftRegistryWrapper {
        margin: 0 auto;
        margin-top: var(--generator-spacing-200);
        max-width: 600px;
    }
}

.theme4,
.theme10,
.theme7,
.theme8,
.theme9,
.theme5,
.theme11,
.theme6 {
    @extend %themeBase;
}

.theme4 {
    .contentWrapper {
        padding: var(--generator-content-box-padding);
    }
}
