@import "../../../../../../style/variables.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    gap: $baseline * 2;
    
    @media (min-width: 600px) {
        flex-direction: row;
    }

    .thumbnail {
        display: flex;
        flex: 1;
    }

    .options {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: $baseline;
    }
}
