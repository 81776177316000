@import "../../style/variables.scss";
@import "../DrawerSlot/DrawerSlot.module.scss";

.drawer {
    background: #383838;
    width: $drawer-width;
    max-width: 100%;
    min-width: 320px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: $z-drawer;
    transition: transform 0.3s ease-in;
    transform: translateX(-100%);
    color: #eeeeee;

    display: flex;
    flex-direction: column;

    .top {
        display: flex;
        justify-content: space-between;
        line-height: $baseline;
        padding: $baseline;

        .title {
            font-weight: bold;
        }
    }

    .actionBar {
        height: 84px;
        min-height: 84px;
        max-height: 84px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 $baseline;
    }

    .content {
        flex: 1;
        padding: $baseline;
        overflow-y: auto;

        // Very weird iOS bug is fixed by this. When this is not present the scroll will not work. Now we have a scroll all the time and that fixes things (sort of)
        .iOSScrollFix {
            @media (max-width: ($drawerCoversContent - 1px)) {
                min-height: calc(100% + 1px);
            }
        }
    }
}

:global([data-open-drawer]) > .drawer.open {
    transform: none;
}
