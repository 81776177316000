@import "../../style/variables.scss";

.wrapper {
    background: #252526;
    border-radius: 4px;

    &.selectable {
        transition: 0.1s ease-in background;
        cursor: pointer;

        &:hover {
            background: rgba(0, 0, 0, 0.1);
        }
    }

    &.padding {
        padding: $baseline;
    }
}