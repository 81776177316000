@import "../../style/variables.scss";

.text {
    padding: $baseline;
    padding-top: 0;
}

.buttonWrapper {
    padding: $baseline;
    padding-top: 0;
}

