@import "../../../../../../../../style/variables.scss";

.button {
    background: transparent;
    border: 0;
    padding: $baseline ($baseline / 2);
    height: $baseline * 3;
    cursor: pointer;
    color: #fff;

    transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out, color 0.2s ease-in-out;

    svg {
        height: $baseline;
    }

    &.disabled {
        opacity: 0.5;
    }

    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;

    @media (min-width: 400px) {
        min-width: $baseline * 2;
        font-size: 12px;
    }

    &.active {
        color: #73c991;
    }

    &:hover {
        background: rgba(255, 255, 255, 0.1);
    }
}
