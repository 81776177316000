@import "../../../../../../style/variables.scss";

.questionValueWrapper {
    display: block;

    .label {
        font-size: 12px;
        font-weight: bold;
        color: #444;
        text-transform: uppercase;
        margin-bottom: $baseline / 4;
    }
}

.wrapper {
    max-width: 1600px;
    margin: $baseline auto;
    padding: 0 $baseline;

    .intro {
        font-size: 18px;
        margin-bottom: $baseline * 2;
    }

    .totalCount {
        font-size: 14px;
        font-weight: normal;
        display: block;
    }
}
